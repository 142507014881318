export default {

  setBasketReservationId(state, id) {
    state.basketReservationId = id;
  },


  setIsBusy(state, status) {
    state.isBusy = status;
  },

  setErrorStatus(state, status) {
    state.errorStatus = status;
  },

  setErrorTitle(state, title) {
    state.errorTitle = title;
  },

  setErrorDescription(state, description) {
    state.errorDescription = description;
  },

  setProductToEdit(state, product) {
    state.productToEdit = product;
  },

  setProductToRemove(state, product) {
    state.productToRemove = product;
  },

  setBasket(state, basket) {
    state.basket = basket;
  },

  setPromoApplied(state, flag) {
    state.discountApplied = flag;
  },

  setNonDiscountedPrice(state, price) {
    state.nonDiscountedPrice = price;
  },

  setCount(state, count) {
    state.count = count;
  },

  setProductAdded(state, product) {
    state.productAdded = product;
  },


  setHoldItemText(state, text) {
    state.holdItemText = text;
  },

  setRelatedProducts(state, products) {
    state.relatedProducts = products;
  },

  setProductRemoved(state, status) {
    state.productRemoved = status;
  },

  setProductEdited(state, status) {
    state.productEdited = status;
  },

  setTotal(state, total) {
    state.total = total;
  },

  setSubTotal(state, subTotal) {
    state.subTotal = subTotal;
  },

  setPromoCodes(state, promoCodes) {
    state.promoCodes = promoCodes;
  }

};