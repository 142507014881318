<template>
  <div class="filter-index">
    <div class="row">
      <div class="col">
        <div class="preferences-bar">
          <div class="result-types">
            <span class="result-types__current">{{ productsLinkText }}</span>
            <a :href="contentSearchPageLink" class="result-types__link">{{
              articlesLinkText
            }}</a>
          </div>

          <Filtr :store="store" name="SortOrder" :label="sortText" />

          <button
            v-if="isMobile"
            class="btn display-filter-btn"
            @click="$refs.filters.viewMobileFilters()"
          >
            {{ filterText }}
          </button>

          <AppliedFilters
            v-if="!isMobile"
            :store="store"
            name="AppliedFilters"
            :label="resultsText"
          />

          <div class="filter-intro" v-if="!isMobile">
            <h1 class="filter-intro__title" v-if="DisplayText">
              {{ DisplayText }}
            </h1>
            <p class="filter-intro__subtitle" v-if="resultsFoundText">
              {{ items.length }} {{ resultsFoundText }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-4 col-devider order-12">
        <Filters
          ref="filters"
          :store="store"
          :filter-text="filterText"
          :filter-close-text="filterCloseText"
          :filter-clear-text="filterClearText"
          :results-text="resultsText"
          :price-filter-text="priceFilterText"
        >
          <AppliedFilters
            v-if="isMobile"
            :store="store"
            name="AppliedFilters"
            :label="resultsText"
          />
        </Filters>
      </div>

      <div class="col-lg-8">
        <div class="filter-intro" v-if="isMobile">
          <h1 class="filter-intro__title" v-if="DisplayText">
            {{ DisplayText }}
          </h1>
          <p class="filter-intro__subtitle" v-if="resultsFoundText">
            {{ items.length }} {{ resultsFoundText }}
          </p>
        </div>

        <div class="general-list-block">
          <ol class="card-list">
            <li
              class="card-list__item card-list__item--two-col"
              v-for="item in items"
              :key="item.Id"
            >
              <filter-index-item :item="item" :read-more-text="readMoreText">
              </filter-index-item>
            </li>
          </ol>
        </div>

        <button
          class="btn btn--alt2 load-more-btn"
          v-if="itemsRemaining > 0"
          @click="loadMore"
        >
          {{ loadMoreButtonText }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, nextTick, watch } from 'vue';
import ResultsList from './ResultsList.vue';
import EventBus from '@/vue/EventBus';
import * as card from '../../modules/card.js';
import * as helper from '../../modules/helpers.js';
import { isMobile } from '../mixins/isMobile.js';

export default {
  ...ResultsList,
  name: 'FilterIndex',
  mixins: [isMobile],

  props: {
    ...ResultsList.props,
    store: {
      type: String,
      default: 'filter-index',
    },
    filterCloseText: String,
    filterClearText: String,
    filterText: String,
    resultsText: String,
    resultsFoundText: String,
    sortText: String,
    priceFilterText: String,
    contentSearchPageUrl: String,
    productsLinkText: String,
    articlesLinkText: String,
    readMoreText: String,
  },

  setup(props) {
    const { items, itemsRemaining, DisplayText, loadMore } =
      ResultsList.setup(props);

    const contentSearchPageLink = computed(() => {
      const currentRouteQuery = new URLSearchParams(location.search);
      return `${props.contentSearchPageUrl}?${currentRouteQuery.toString()}`;
    });

    // TODO: replace EventBus
    // onMounted(() => {
    //   nextTick().then(() => {
    //     EventBus.$on('ITEMS_LOADED', () => {
    //       card.init();
    //       helper.init();
    //       picturefill();
    //       new lazyloadPicturefillBackground();
    //     });
    //   });
    // });

    return {
      items,
      itemsRemaining,
      DisplayText,
      loadMore,
      contentSearchPageLink,
    };
  },
};
</script>
