<template>
  <section class="section newsletter-block">
    <div class="newsletter-block__inner">
      <div
        class="newsletter-block__background picturefill-background is-lazy"
        v-if="contentString.BackgroundImageUrlXs"
      >
        <span
          class="picturefill-background-source"
          :data-src="contentString.BackgroundImageUrlXs"
        ></span>
        <span
          class="picturefill-background-source"
          :data-src="contentString.BackgroundImageUrlXs"
          data-media="(min-width: 425px)"
        ></span>
        <span
          class="picturefill-background-source"
          :data-src="contentString.BackgroundImageUrlSm"
          data-media="(min-width: 768px)"
        ></span>
        <span
          class="picturefill-background-source"
          :data-src="contentString.BackgroundImageUrlMd"
          data-media="(min-width: 1200px)"
        ></span>
        <span
          class="picturefill-background-source"
          :data-src="contentString.BackgroundImageUrlLg"
          data-media="(min-width: 1600px)"
        ></span>
      </div>
      <div class="container-fluid">
        <div class="row">
          <div class="col">
            <div class="newsletter-block__content" v-if="!successMessage">
              <h2 class="newsletter-block__title">
                {{ contentString.Heading }}
              </h2>
              <p class="newsletter-block__intro">
                {{ contentString.SubHeading }}
              </p>

              <form
                id="newsletter-form"
                class="newsletter-block_form"
                novalidate="novalidate"
                @submit.prevent="handleSubmit"
              >
                <div class="newsletter-block__inputs">
                  <div class="form-tooltip" v-if="toolTip == true">
                    <button class="form-tooltip__close" @click="toolTipClick()">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="20"
                        viewBox="0 0 18 20"
                      >
                        <use
                          xlink:href="/Static/images/sprites/symbols.svg#icon--close"
                        />
                      </svg>
                    </button>
                    <p class="form-tooltip__title">
                      {{ contentString.TitleTooltip }}
                    </p>
                    <p class="form-tooltip__subtitle">
                      {{ contentString.SubtitleTooltip }}
                    </p>
                    <p class="form-tooltip__body">
                      {{ contentString.BodyTooltip }}
                    </p>
                  </div>

                  <div
                    class="form__group newsletter-block__input-group newsletter-block__input-group--first-name"
                  >
                    <button
                      class="form-tooltip__open"
                      @click.prevent="toolTipClick()"
                    >
                      i
                    </button>
                    <label for="newsletterFirstName" class="visually-hidden">{{
                      contentString.FirstNamePlaceholder
                    }}</label>
                    <input
                      type="text"
                      class="newsletter__input"
                      id="newsletterFirstName"
                      name="firstNameInput"
                      :placeholder="contentString.FirstNamePlaceholder"
                      required
                      v-model.trim="postData.FirstName"
                      data-msg="Please enter your first name"
                      @focus="toolTipFocus()"
                    />
                  </div>
                  <div class="form__group newsletter-block__input-group">
                    <label for="newsletterLastName" class="visually-hidden">{{
                      contentString.LastNamePlaceholder
                    }}</label>
                    <input
                      type="text"
                      class="newsletter__input"
                      id="newsletterLastName"
                      name="lastNameInput"
                      :placeholder="contentString.LastNamePlaceholder"
                      required
                      v-model.trim="postData.LastName"
                      data-msg="Please enter your last name"
                      @focus="toolTipFocus()"
                    />
                  </div>

                  <div
                    class="form__group newsletter-block__input-group newsletter-block__input-group--email"
                  >
                    <label for="newsletterEmail" class="visually-hidden">{{
                      contentString.EmailAddressPlaceholder
                    }}</label>
                    <input
                      type="email"
                      class="newsletter__input"
                      id="newsletterEmail"
                      name="emailInput"
                      :placeholder="contentString.EmailAddressPlaceholder"
                      required
                      autocomplete="email"
                      v-model.trim="postData.Email"
                      data-msg="Please enter a valid email address"
                      @focus="toolTipFocus()"
                    />
                  </div>

                  <div
                    class="form__group newsletter-block__input-group newsletter-block__input-group--select"
                  >
                    <label for="newsletterCountry">{{
                      contentString.CountryLabel
                    }}</label>
                    <select
                      class="newsletter__input"
                      name="newsletterCountry"
                      id="newsletterCountry"
                      required
                      data-msg="Please select your country of residence"
                      v-model="postData.Country"
                      @focus="toolTipFocus()"
                    >
                      <option
                        v-for="(option, i) in contentString.CountryList"
                        :key="i"
                        :selected="option.Selected"
                        :value="option.Value"
                        :disabled="option.Disabled"
                      >
                        {{ option.Text }}
                      </option>
                    </select>
                  </div>
                </div>

                <fieldset class="newsletter-block__checkboxes">
                  <legend class="visually-hidden">
                    {{ contentString.heading }}
                  </legend>
                  <div class="newsletter-block__checkbox-wrap">
                    <div class="default-checkbox newsletter-block__checkbox">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="evanEvansCheck"
                        value="evanevans"
                        v-model="postData.EvanEvansNewsletter"
                        name="newsletters[]"
                        data-msg="Please select at least one of the check boxes above"
                      />
                      <label class="form-check-label" for="evanEvansCheck">{{
                        contentString.NewsletterSignupCopy
                      }}</label>
                    </div>
                  </div>

                  <div class="newsletter-block__checkbox-wrap">
                    <div class="default-checkbox newsletter-block__checkbox">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="treadrightCheck"
                        value="treadright"
                        v-model="postData.TreadRightNewsletter"
                        name="newsletters[]"
                      />
                      <label class="form-check-label" for="treadrightCheck">{{
                        contentString.TreadRightSignupCopy
                      }}</label>
                    </div>
                  </div>
                </fieldset>

                <small class="newsletter-block__smallprint">
                  {{ contentString.PrivacyPolicyText }}
                  <a
                    :href="contentString.PrivacyPolicyUrl"
                    :target="contentString.PrivacyPolicyTarget"
                    :rel="contentString.Nofollow ? 'nofollow' : false"
                    >{{ contentString.PrivacyPolicyName }}</a
                  >
                </small>
                <button type="submit" class="btn btn--thin btn--alt3">
                  {{ contentString.CtaText }}
                </button>
              </form>
            </div>

            <div
              class="newsletter-block__content newsletter-block__content--thankyou"
              v-if="successMessage"
            >
              <h2 class="newsletter-block__title">
                {{ contentString.ThankYouSubTitle }}
              </h2>
              <p class="newsletter-block__intro">
                {{ contentString.ThankYouTitle }}
              </p>
              <small class="newsletter-block__smallprint">
                {{ contentString.ThankYouPrivacy }}
                <a
                  :href="contentString.PrivacyPolicyUrl"
                  :rel="contentString.Nofollow ? 'nofollow' : false"
                  >Privacy Policy</a
                >
              </small>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { ref } from 'vue';

export default {
  name: 'Newsletter',
  props: {
    contentString: Object,
  },
  setup(props) {
    const toolTip = ref(null);
    const successMessage = ref(false);
    const postData = ref({
      EvanEvansNewsletter: false,
      TreadRightNewsletter: false,
      FirstName: '',
      LastName: '',
      Email: '',
      Country: '',
    });

    const handleSubmit = () => {
      if ($('#newsletter-form').valid()) {
        fetch('/umbraco/api/newsletterblockapi/signup', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(postData.value),
        })
          .then(response => {
            if (response.ok) {
              return response.json();
            } else {
              throw new Error('Network response was not ok');
            }
          })
          .then(data => {
            console.log('success: ' + JSON.stringify(data, null, 2));
            successMessage.value = true;
          })
          .catch(error => {
            console.log('fail: ' + JSON.stringify(error));
          });

        // GA tracking event on form submission.
        dataLayer.push({
          event: 'newsletter',
        });
      }
    };

    const toolTipFocus = () => {
      if (toolTip.value === null) {
        toolTip.value = true;
      }
    };

    const toolTipClick = () => {
      toolTip.value = !toolTip.value;
    };

    return {
      toolTip,
      successMessage,
      postData,
      handleSubmit,
      toolTipFocus,
      toolTipClick,
    };
  },
  mounted() {
    jQuery.validator.addMethod(
      'regex',
      function (value, element, regexp) {
        if (regexp.constructor != RegExp) regexp = new RegExp(regexp);
        else if (regexp.global) regexp.lastIndex = 0;
        return this.optional(element) || regexp.test(value);
      },
      'erreur expression reguliere',
    );

    $('#newsletter-form').validate({
      rules: {
        'newsletters[]': {
          required: true,
        },
        email: {
          validateEmail: true,
        },
        firstNameInput: {
          required: true,
          regex: /^[a-zA-Z][\sa-zA-Z,'‘’-]*[a-zA-Z']$/,
        },
        lastNameInput: {
          required: true,
          regex: /^[a-zA-Z][\sa-zA-Z,'‘’-]*[a-zA-Z']$/,
        },
      },
      errorElement: 'div',
      errorPlacement: function (error, element) {
        error.addClass('invalid-feedback');
        $('#newsletter-form').addClass('was-validated');
        if (element.prop('type') === 'checkbox') {
          error.insertAfter(
            $(
              '.newsletter-block__checkbox-wrap:last .newsletter-block__checkbox',
            ),
          );
        } else {
          error.insertAfter(element);
        }
      },
      success: function (label, element) {},
    });
  },
  // created() {
  //   this.$nextTick().then(() => {
  //     picturefillBackground();
  //     new lazyloadPicturefillBackground();
  //   });
  // },
};
</script>
