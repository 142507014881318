<template>
  <div class="spinner">
    <label :for="id" class="spinner__label">{{ label }}</label>
    <div class="spinner__body">
      <button class="spinner__btn spinner__btn--minus" @click="decrease">
        <span class="visually-hidden">-</span>
      </button>
      <input
        type="number"
        :id="id"
        class="spinner__number"
        :min="min"
        :max="max"
        v-model.number="currentValue"
        @keypress="onKeypress"
        @input="inputValue"
      />
      <button class="spinner__btn spinner__btn--plus" @click="increase">
        <span class="visually-hidden">+</span>
      </button>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, watch } from 'vue';

export default {
  name: 'Spinner',
  props: {
    label: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    value: {
      type: Number,
      default: 0,
    },
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: 99,
    },
  },
  setup(props, { emit }) {
    const currentValue = ref(props.value);

    const increase = () => {
      currentValue.value++;
    };

    const decrease = () => {
      currentValue.value--;
    };

    const onKeypress = e => {
      const key = String.fromCharCode(e.keyCode || e.which);
      const regex = /[0-9]/;
      if (!regex.test(key)) {
        e.returnValue = false;
        if (e.preventDefault) {
          e.preventDefault();
        }
      }
    };

    const inputValue = evt => {
      currentValue.value = evt.target.value
        ? parseInt(evt.target.value)
        : props.min;
    };

    onMounted(() => {
      emit('input', currentValue.value);
    });

    watch(currentValue, value => {
      if (value <= props.min) {
        currentValue.value = parseInt(props.min);
      }
      if (value >= props.max) {
        currentValue.value = parseInt(props.max);
      }
      if (value <= props.max && value >= props.min) {
        emit('input', value);
      }
    });

    return {
      currentValue,
      increase,
      decrease,
      onKeypress,
      inputValue,
    };
  },
};
</script>
