import State from './state';
import Mutations from './mutations';
import Getters from './getters';
import Actions from './actions';

export default {
  name: 'basket',
  namespaced: true,
  mutations: Mutations,
  getters: Getters,
  actions: Actions,
  state() {
    return State
  }
};