import { config } from '../config';


const OFFSET = 80;

const init = () => {

  if ($('.social-sharing--sticky').length) {

    enquire.register(`screen and (max-width: ${config.breakpoint.lgMax}px)`, {
      match: function () {
        removeSticky();
      }
    });
  
    enquire.register(`screen and (min-width: ${config.breakpoint.xlMin}px)`, {
      match: function () {
        setupSticky();
      },
    });

  }

};


const removeSticky = () => {
  $(document).unbind('scroll');
  $('.social-sharing--sticky').removeClass('fade-out');
  $('.social-sharing--sticky').removeClass('fade-in');
  $('.social-sharing--sticky').addClass('start-view');
  $('.social-sharing--sticky').css('width', '').css('top', '');
}


const setupSticky = () => {

  $(document).scroll(function() {


    let startYPos = $('.social-sharing-sticky-content').offset().top - $('.sticky-header').outerHeight();
    // we now work out the bottom position for when we remove the sticky search
    let endYPos = $('.social-sharing-sticky-content').outerHeight() + startYPos - OFFSET;
    endYPos = endYPos - $('.social-sharing--sticky').outerHeight(); // remove height of our sticky menu

    // if social share also exists in the content we take that in to account as well
    if ($('.social-sharing-sticky-content .social-sharing').length) {
      endYPos = endYPos - $('.social-sharing-sticky-content .social-sharing').outerHeight();
    }

    // check if we are within the threshold to display sticky
    if($(document).scrollTop() >= endYPos) {
      $('.social-sharing--sticky').addClass('fade-out');
      $('.social-sharing--sticky').removeClass('fade-in');
      $('.social-sharing--sticky').css('top', '');
    } else if($(document).scrollTop() >= startYPos ) {
      $('.social-sharing--sticky').removeClass('start-view');
      $('.social-sharing--sticky').removeClass('fade-out');
      $('.social-sharing--sticky').addClass('fade-in');
      $('.social-sharing--sticky').css({ top: $('.sticky-header').height() + OFFSET });

    } else {
      $('.social-sharing--sticky').addClass('start-view');
      $('.social-sharing--sticky').removeClass('fade-in');
      $('.social-sharing--sticky').css('top', '');
    }
  });
}

export { init }