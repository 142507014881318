<template>
  <div class="dropdown-filter">
    <label>
      <span class="dropdown-filter__label">{{ label }}</span>
      <select class="dropdown-filter__select" v-model="selectedValue">
        <!-- turning this on turns it on for all filters, need to make a decision -->
        <!-- <option value="" :selected="!Selected.length">{{label}}</option> -->
        <option
          v-for="(option, optionKey) in options"
          :value="getKey(option)"
          :selected="selectedValue == getKey(option)"
          :key="optionKey"
        >
          {{ getValue(option) }}
        </option>
      </select>
    </label>
  </div>
</template>

<script>
import { ref, computed, watch } from 'vue';
import { useStore } from 'vuex';

export default {
  name: 'Filtr',
  props: {
    store: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: 'Filter',
    },
    name: {
      type: String,
      default: 'Filter',
    },
    onChange: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const store = useStore();
    const selectedValue = ref(store.state[props.store][props.name]);

    const options = computed(
      () => store.state[props.store][`${props.name}Options`],
    );

    const getKey = option => option.Key || option;
    const getValue = option => option.Value || option;

    watch(
      () => selectedValue.value,
      newValue => {
        store.commit(`${props.store}/set${props.name}`, newValue);
        if (props.onChange) {
          store.dispatch(`${props.store}/fetchItems`);
        }
      },
    );

    watch(
      () => store.state[props.store][props.name],
      newValue => {
        selectedValue.value = newValue;
      },
    );

    return {
      selectedValue,
      options,
      getKey,
      getValue,
    };
  },
};
</script>
