<template>
  <transition name="fade">
    <span class="basket-count" v-if="count > 0">{{ count }}</span>
  </transition>
</template>

<script>
import { computed, onMounted } from 'vue';
import { useStore } from 'vuex';

export default {
  name: 'BasketCount',
  props: {
    store: {
      type: String,
      default: 'basket',
    },
    hello: String,
  },
  setup(props) {
    const store = useStore();
    const count = computed(() => store.state[props.store].count);

    onMounted(() => {
      if (count.value == null) {
        store.dispatch(`${props.store}/loadCount`);
      }
    });

    return {
      count,
    };
  },
};
</script>
