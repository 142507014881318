let options = {
  winHeight: 350,
  winWidth: 520,
  shareLink: '.js-share-page'
};

let _setPosition = function() {
  let winTop = (screen.height / 2) - (options.winHeight / 2);
  let winLeft = (screen.width / 2) - (options.winWidth / 2);
  let sharingPosition = 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,width=' + options.winWidth + ',height=' + options.winHeight + ',top=' + winTop + ',left=' + winLeft;
  return sharingPosition;
};

const init = () => {
  $(document).on( 'click', options.shareLink, function(e) {
    _initShareHandlers(e);
  });
};

const _initShareHandlers = (e) => {
  let theShare = $(e.target).closest('.js-share-page');
  let thisUrl = window.location.href;
  let pageTitle = document.title;

  switch (theShare.attr('data-title')) {

  case 'twitter-share':
    window.open('http://twitter.com/intent/tweet?source=webclient&url=' + thisUrl + '',
      theShare.attr('data-title'),
      _setPosition());
    break;

  case 'facebook-share':
    window.open('http://www.facebook.com/sharer.php?&u=' + encodeURIComponent(thisUrl) + '',
      theShare.attr('data-title'),
      _setPosition());
    break;

  case 'whatsapp-share':
    window.open('https://api.whatsapp.com/send?text=' + pageTitle + ' - ' + thisUrl + '',
      theShare.attr('data-title'),
      _setPosition());
    break;

  };


};

export { init }