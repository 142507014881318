const init = () => {

  // tool tip logic for checkout your details page
  let userClosed = false;

  // when input is first focused
  $('.js-tooltip-trigger').focusin(function() {
    if(!userClosed) {
      $('.form__group').removeClass('form__group--focused');
      $(this).closest('.form__group').addClass('form__group--focused');
      userClosed = true;
    }
  });

  // X to close tooltip
  $('.form-tooltip__close').click(function(event) {
    event.preventDefault();
    $(this).closest('.form__group').removeClass('form__group--focused');
    userClosed = true;
  });

  // i to open tooltip
  $('.js-tooltip-trigger-btn').click(function(event) {
    event.preventDefault();
    $('.form__group').removeClass('form__group--focused');
    $(this).closest('.form__group').toggleClass('form__group--focused');
  });

  // country of residnce validation
  // two inputs, text input only required if selection hasn't been made via dropdown
  $('#country-select').change(function() {
    let countrySelectValue = $('#country-select option:checked').val();
    if( countrySelectValue !== '') {
      $('#country-input').removeAttr('required');
    } else {
      $('#country-input').attr('required', 'true');
    }
  });

};

export { init }