<template>
  <div class="basket-promo">
    <form class="basket-promo__form" autocomplete="off">
      <label for="promocode" class="visually-hidden">{{ enterCodeText }}</label>
      <input
        class="form-control basket-promo__input"
        :class="{ 'is-invalid': isError }"
        type="text"
        id="promocode"
        name="promocode"
        :placeholder="enterCodeText"
        v-model="input"
        @keyup="isError = false"
      />
      <button
        type="submit"
        class="btn btn--alt2 basket-promo__apply"
        @click.stop.prevent="applyCode"
      >
        {{ applyText }}
      </button>
      <div class="invalid-feedback">{{ invalidCodeText }}</div>
    </form>
  </div>
</template>

<script>
import { ref } from 'vue';
import { useStore } from 'vuex';

export default {
  name: 'PromoCode',
  props: {
    store: {
      type: String,
      default: 'basket',
    },
    currencyCode: {
      type: String,
      default: 'GBP',
    },
    enterCodeText: String,
    applyText: String,
    invalidCodeText: String,
  },
  setup(props) {
    const store = useStore();
    const isError = ref(false);
    const input = ref(null);

    const applyCode = () => {
      const promo = encodeURIComponent(input.value);
      fetch(
        `/umbraco/Api/ReservationsApi/AddPromoCode?promocode=${promo}&originalCost=${
          store.state[props.store].total
        }`,
        {
          method: 'POST',
        },
      )
        .then(response => {
          if (response.ok) {
            store.commit(`${props.store}/setPromoApplied`, true);
            return response.json();
          } else {
            throw new Error('Network response was not ok');
          }
        })
        .then(() => {
          store.dispatch(`${props.store}/loadBasket`);
        })
        .catch(error => {
          console.log(error);
          isError.value = true;
        });
    };

    return {
      isError,
      input,
      applyCode,
    };
  },
};
</script>
