import State from '@/vuex/stores/base/state';
import Mutations from '@/vuex/stores/base/mutations';
import Getters from '@/vuex/stores/base/getters';
import Actions from '@/vuex/stores/base/actions';

export default {
  name: 'search',
  namespaced: true,
  mutations: Mutations,
  getters: Getters,
  actions: Actions,
  state() {
    return State
  }
};