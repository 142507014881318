import State from '@/vuex/stores/base/state';
import Mutations from '@/vuex/stores/base/mutations';
import Getters from '@/vuex/stores/base/getters';

// custom state/mutations/getters/actions for the content store
import ContentIndexState from '@/vuex/stores/content-index/state';
import ContentIndexMutations from '@/vuex/stores/content-index/mutations';
import ContentIndexGetters from '@/vuex/stores/content-index/getters';
import ContentIndexActions from '@/vuex/stores/content-index/actions';


export default {
  name: 'content-index',
  namespaced: true,
  mutations: {
    ...Mutations,
    ...ContentIndexMutations
  },

  getters: {
    ...Getters,
    ...ContentIndexGetters
  },

  actions: ContentIndexActions,

  state() {
    return {
      ...State,
      ...ContentIndexState
    }
  }

};