const init = () => {

  // GA tracking - form submissions
  $('form').not('#newsletter-form, #checkout-form').submit(function() {
    // get form name
    let formName = $('#FormName').val();

    dataLayer.push({
      'event': 'formSubmit',
      'formName': formName
    });
  });


  // document on as some elements rendered after DOM
  $(document).on('click','.mfp-arrow, .js-tour-highlights', function() {

    // this class only present when video rendered
    if($('.mfp-iframe-scaler').length) {

      // video has no identifiers so add one
      $('.mfp-iframe-scaler .mfp-iframe').attr('id', 'modalVideo');

      // delay as it's rendered after a small pause
      setTimeout(function() {

        // get contents of iframe
        let iframe = document.getElementById('modalVideo');
        let innerDoc = iframe.contentDocument || iframe.contentWindow.document;

        // grab video from iframe
        let media = innerDoc.getElementsByTagName('video')[0];
        // add playsinline for autoplay on IOS devices
        $(media).attr('playsinline', '');

        // video name
        let videoName = $('#modalVideo').attr('src');

        // initial play event and video auto plays
        dataLayer.push({
          'event': 'video',
          'videoName': videoName,
          'videoStatus': 'playing'
        });

        // Pause event
        $(media).get(0).addEventListener('pause', function() {

          dataLayer.push({
            'event': 'video',
            'videoName': videoName,
            'videoStatus': 'paused'
          });

        });

        // End event
        $(media).get(0).addEventListener('ended', function() {

          dataLayer.push({
            'event': 'video',
            'videoName': videoName,
            'videoStatus': 'ended'
          });

        });

        // Play event
        $(media).get(0).addEventListener('playing', function() {

          dataLayer.push({
            'event': 'video',
            'videoName': videoName,
            'videoStatus': 'playing'
          });

        });

      }, 1500);



    }

  });





};

export { init }