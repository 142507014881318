export default {

  // return an api url (appends parentId if set)
  apiUrl: state => (type) => {
    let url = state[type];
    if (state.parentId) {
      url += `/${state.parentId}`;
    }
    return url;
  },

  // get data which will be saved in the URL
  // AND sent to the server
  urlData: state => {
    return {
      SearchTerm: state.SearchTerm,
      Tag: state.Tag,
      SortOrder: state.SortOrder,
      Categories: state.Categories,
      Take: state.Take,
      MinPrice: state.MinPrice,
      MaxPrice: state.MaxPrice,
      DisplayText: state.DisplayText,
      Culture: state.Culture
    }
  },

  // get data which will be sent to the server
  // NOT saved in the URL
  serverData: state => {
    const serverData = {
      ShowFilters: state.ShowFilters,
      From: state.From,
      ChildItemsOnly: state.childItemsOnly,
      IsSiteSearch: state.isSiteSearch,
    };
    if (state.parentId) {
      serverData.ParentId = state.parentId;
    }
    return serverData;
  }

};