<template>
  <div
    id="site-search"
    class="site-search"
    :class="{ 'site-search--alt': alt }"
  >
    <form
      action="/"
      class="form site-search__form"
      enctype="multipart/form-data"
      method="post"
      role="search"
      autocomplete="off"
      @submit.prevent
    >
      <label class="visually-hidden" :for="inputName">{{ label }}</label>
      <input id="FormName" name="FormName" type="hidden" value="site search" />
      <input
        :id="inputName"
        class="site-search__term"
        type="search"
        :placeholder="placeholder"
        v-model.trim="searchTerm"
        @keydown.down="onArrowDown"
        @keydown.up="onArrowUp"
        @keydown.enter="onEnter"
        autocomplete="off"
        autocorrect="off"
      />

      <div class="site-search__results" v-show="isOpen && items.length > 0">
        <ul class="site-search__results-list">
          <li
            class="site-search__result-item"
            v-for="(item, i) in items"
            :key="i"
          >
            <a
              :href="item.url"
              class="site-search__result-link"
              :class="[
                { 'is-active': i === arrowCounter },
                'site-search__result-link--' + item.type,
              ]"
              @click="selectResult(item.url)"
            >
              <template v-if="item.thumbnail">
                <div class="site-search-result-image-holder">
                  <img
                    :data-src="item.thumbnail"
                    :alt="item.title"
                    class="site-search__result-image media lazyload"
                    data-object-fit="cover"
                  />
                </div>
              </template>
              <p>{{ item.title }}</p>
            </a>
          </li>
        </ul>
        <a
          :href="searchLink"
          class="site-search__view-all arrow-link"
          :class="[{ 'is-active': arrowCounter === items.length }]"
          >{{ viewAllText }}</a
        >
      </div>
      <button
        id="btnSiteSearch"
        class="site-search__btn"
        @click="goToSearchResultsPage"
        @focus="closeAutocomplete"
      >
        {{ btnText }}
      </button>
    </form>
  </div>
</template>

<script>
import { ref, computed, watch, onMounted, onUpdated, onUnmounted } from 'vue';
import debounce from 'lodash/debounce';

export default {
  name: 'PredictiveSearch',
  props: {
    label: {
      type: String,
      default: 'Search',
    },
    placeholder: {
      type: String,
      default: '',
    },
    btnText: {
      type: String,
      default: '',
    },
    viewAllText: {
      type: String,
      default: '',
    },
    url: {
      type: String,
      default: '',
    },
    culture: {
      type: String,
      default: '',
    },
    alt: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const isOpen = ref(false);
    const searchTerm = ref('');
    const resultData = ref(null);
    const arrowCounter = ref(-1);
    const searchLink = computed(
      () => props.url + '?SearchTerm=' + searchTerm.value,
    );
    const inputName = computed(() =>
      props.alt ? 'txtSearchTerm' : 'txtSearchTermAlt',
    );
    const items = computed(() => {
      let ar = [];
      if (resultData.value) {
        for (let type in resultData.value) {
          let items = resultData.value[type];
          const len = Math.min(items.length, 2);
          for (let j = 0; j < len; j++) {
            let item = items[j];
            if (item.Image != null) {
              ar.push({
                type: type.toLowerCase(),
                title: item.Name,
                url: item.Url,
                thumbnail: item.Image.SmUrl,
              });
            } else {
              ar.push({
                type: type.toLowerCase(),
                title: item.Name,
                url: item.Url,
              });
            }
          }
        }
      }
      return ar;
    });

    const getAutoCompleteItems = () => {
      fetch('/umbraco/api/globalsearchapi/predictiveItems', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          SearchTerm: searchTerm.value,
          Culture: props.culture,
        }),
      })
        .then(response => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error('Network response was not ok');
          }
        })
        .then(data => {
          isOpen.value = true;
          if (resultData.value !== data) {
            resultData.value = data;
            arrowCounter.value = -1;
          }
        })
        .catch(error => {
          console.log(error);
        });
    };

    const autocompleteDebounced = debounce(() => {
      getAutoCompleteItems();
    }, 500);

    const onArrowDown = e => {
      if (arrowCounter.value < items.value.length) {
        arrowCounter.value += 1;
      }
    };

    const onArrowUp = e => {
      e.preventDefault();
      if (arrowCounter.value > 0) {
        arrowCounter.value -= 1;
      }
    };

    const onEnter = e => {
      if (
        arrowCounter.value !== -1 &&
        arrowCounter.value < items.value.length
      ) {
        e.preventDefault();
        let item = items.value[arrowCounter.value];
        selectResult(item.url);
      } else {
        goToSearchResultsPage();
      }
    };

    const goToSearchResultsPage = () => {
      window.location.href = searchLink.value;
    };

    const handleClickOutside = e => {
      if (!e.target.closest('.predictive-search-container')) {
        closeAutocomplete();
      }
    };

    const selectResult = url => {
      closeAutocomplete();
      window.location.href = url;
    };

    const closeAutocomplete = () => {
      isOpen.value = false;
      arrowCounter.value = -1;
    };

    watch(searchTerm, () => {
      autocompleteDebounced();
    });

    onMounted(() => {
      document.addEventListener('click', handleClickOutside);
    });

    onUpdated(() => {
      objectFitPolyfill();
    });

    onUnmounted(() => {
      document.removeEventListener('click', handleClickOutside);
    });

    return {
      isOpen,
      searchTerm,
      resultData,
      arrowCounter,
      inputName,
      items,
      searchLink,
      autocompleteDebounced,
      onArrowDown,
      onArrowUp,
      onEnter,
      handleClickOutside,
      selectResult,
      goToSearchResultsPage,
    };
  },
};
</script>
