<!--
  This is the example list which contains all the elements we should need
  - Search
  - SortOrder
  - Filters
  - Item

  See content-filter.vue or content-library.vue for example on how to create a new list template
-->

<template>
  <div class="list-example">
    <Search :store="store" />
    <Filtr :store="store" name="SortOrder" label="Sort By" />
    <Filters :store="store" />
    <ol class="list-example__items">
      <li class="list-example__item" v-for="item in items" :key="item.Id">
        <slot :item="item">
          <h2>{{ item.Title }}</h2>
          <p>{{ item.Description }}</p>
        </slot>
      </li>
    </ol>
    <button
      class="btn btn--full-width"
      v-if="itemsRemaining > 0"
      data-tsat="t06-load_more"
      @click="loadMore"
    >
      {{ loadMoreButtonText }}
    </button>
  </div>
</template>

<script>
import { computed, onMounted } from 'vue';
import { useStore, mapState } from 'vuex';
import reduce from 'lodash/reduce';
import Filters from './Filters.vue';
import Search from './Search.vue';
import Filtr from './Filtr.vue';
import AppliedFilters from './AppliedFilters.vue';
import RangeSlider from './RangeSlider.vue';

export default {
  name: 'ResultsList',

  components: {
    Filters,
    Search,
    Filtr,
    AppliedFilters,
    RangeSlider,
  },

  props: {
    culture: String,

    // The vuex store we use for fetching data
    store: {
      type: String,
      default: 'items',
    },

    // parent page id (sent in api requests if set)
    parentId: {
      type: Number,
      default: null,
    },

    // The amount of results we show on load
    startAmount: {
      type: Number,
      default: 2,
    },

    // the amount of extra results we display on load more
    loadMoreAmount: {
      type: Number,
      default: 2,
    },

    // the button text
    loadMoreButtonText: {
      type: String,
      default: 'Load More',
    },

    // whether we are doing a tag search
    tagSearch: {
      type: Boolean,
      default: false,
    },

    // used on main search results page to weed out results that have been turned off in the back end
    isSiteSearch: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const store = useStore();

    const items = computed(() => store.state[props.store].items);
    const itemsRemaining = computed(
      () => store.state[props.store].itemsRemaining,
    );
    const Take = computed(() => store.state[props.store].Take);
    const SearchTerm = computed(() => store.state[props.store].SearchTerm);
    const Tag = computed(() => store.state[props.store].Tag);
    const CurrentSearch = computed(
      () => store.state[props.store].CurrentSearch,
    );
    const DisplayText = computed(() => store.state[props.store].DisplayText);

    const urlData = computed(() => store.getters[`${props.store}/urlData`]);

    // Methods
    const updateSearch = () => {
      store.dispatch(`${props.store}/fetchItems`, true);
    };

    const loadMore = () => {
      store.commit(`${props.store}/setTake`, Take.value + props.loadMoreAmount);
      store.dispatch(`${props.store}/fetchItems`, true);
    };

    const applyRouterData = () => {
      const currentRouteQuery = new URLSearchParams(location.search);
      const missingUrlParams = reduce(
        urlData.value,
        (result, value, key) =>
          currentRouteQuery.has(key) ? result : result.concat(key),
        [],
      );

      missingUrlParams.forEach(attr => {
        currentRouteQuery.set(attr, '');
      });

      currentRouteQuery.forEach((value, key) => {
        const mutationName = `${props.store}/set${key}`;
        try {
          store.commit(mutationName, value);
        } catch (err) {
          console.warn('Mutation does not exist', mutationName);
        }
      });
    };

    // Lifecycle
    onMounted(() => {
      store.commit(`${props.store}/setIsSiteSearch`, props.isSiteSearch);
      store.commit(`${props.store}/setParentId`, props.parentId);
      store.commit(`${props.store}/setInitialTake`, props.startAmount);
      store.commit(`${props.store}/setTake`, props.startAmount);
      applyRouterData();
      store.commit(`${props.store}/setCulture`, props.culture);
      store.dispatch(`${props.store}/fetchItems`, true);
      store.dispatch(`${props.store}/fetchSortOrderOptions`, true);
    });

    return {
      items,
      itemsRemaining,
      Take,
      SearchTerm,
      Tag,
      CurrentSearch,
      DisplayText,
      urlData,
      updateSearch,
      loadMore,
    };
  },
};
</script>
