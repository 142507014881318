import { config } from '../config';

const options = {
  header: '#header',
  primaryNav: '#primary-nav',
  mobileNavIcon: '.mobile-nav-icon',
  activeSubButton: '',
  level2Nav: '.nav-level-2',
  isMobile: null
};


const init = () => {

  createNavigation();
  $('.header-main').css({ opacity: 1 });

  $(document.body).on('touchmove', onScroll); // for mobile
  $(window).on('scroll', onScroll);
  onScroll();

  enquire.register(`screen and (max-width: ${config.breakpoint.mdMax}px)`, {
    match: function () {
      options.isMobile = true;
      destroyDesktopMenu();
      createMobileMenu();
    }
  });

  enquire.register(`screen and (min-width: ${config.breakpoint.lgMin}px)`, {
    match: function () {
      options.isMobile = false;
      destroyMobileMenu();
      createDesktopMenu();
    }
  });

};




const onScroll = () => {
  var startYPos;
  if(options.isMobile) {
    startYPos = 1;
  }else{
    startYPos = $('.secondary-nav').outerHeight();
  }

  if ($(document).scrollTop() > startYPos) {
    $(options.header).addClass('stuck');
  } else {
    $(options.header).removeClass('stuck');
  }
}




const createNavigation = () => {

  $('.nav-level-1__item').has('.nav-level-2').each((index, el) => {

    // create IDs for the btn and content for each item to associate each btn with its content
    let $this = $(el);
    let buttonID = 'nav-btn-' + index;
    let contentID = 'subnav-' + index;
    let $firstLink = $this.find('span:first');
    let linkText = $firstLink.text();

    // Add a button inside the menu
    $firstLink.after(`<button id="${buttonID}" class="nav-button"  aria-expanded="false" aria-controls="${contentID}">${linkText}</button>`);

    let $subNav = $this.find('.nav-level-2');
    $subNav.attr('ID', contentID);
    $subNav.attr('aria-labelledby', buttonID);
    $subNav.attr('aria-hidden', 'true');

    // delete the original link
    $firstLink.remove();

  });

  // add click handler for primary nav buttons
  $('.nav-button').on('click', (e) => {
    let $this = $(e.target);

    if ($this.hasClass('is-active')) {
      closeSubMenu();
      setMenuSolid(false);
    } else {
      openSubMenu(e.target);
      setMenuSolid(true);
    }
  });

  $(window).keyup(function (e) {
    var code = (e.keyCode ? e.keyCode : e.which);
    if (code == 9 && $('.nav-button:focus, .nav-level-1__link:focus, .secondary-nav__link:focus, .search-btn:focus, .basket-link  :focus').length) {
      closeSubMenu();
      setMenuSolid(false);
    }
  });




  // close mobile menu by pressing escape
  // close open submenus on desktop by pressing escape
  // may need a more centralised approach for handling key codes
  $(window).keyup((e) => {
    if (e.keyCode === 27) {
      e.preventDefault();

      // if mobile nav is open, close it
      if ($('html').hasClass('nav-active')) {
        $('html').removeClass('nav-active');
        // set focus back on the element that opened the modal
        $('#mobile-nav-icon').focus();
      }

      // close any open sub-menus on both mobile and desktop
      $('.nav-button.is-active').click();

    }
  });

};




const openSubMenu = (el) => {
  let $el = $(el);

  let animationTime = 300;

  // close any open subnavs
  if (options.activeSubButton) {
    closeSubMenu(0);
    animationTime = 0;
  }

  options.activeSubButton = $el;

  $el
    .addClass('is-active')
    .attr('aria-expanded', true);

  if(options.isMobile) {
    // $el.next().stop().fadeIn(300).animate({left: 0}, 300);
    $el.next().stop().fadeIn(0, 'linear');
  } else{
    $el.next().stop().fadeIn(animationTime, 'linear');
  }
  $el.next().attr('aria-hidden', false);



};


const setMenuSolid = (state) => {
  if(state) {
    $('.header').addClass('is-open');
  }else{
    $('.header').removeClass('is-open');
  }
}


const closeSubMenu = (time) => {

  var animationTime = 300;
  if(Number.isInteger(time)) {
    animationTime = time;
  }

  $(options.activeSubButton)
    .removeClass('is-active')
    .attr('aria-expanded', false);

  var $level2 = $(options.activeSubButton).next();

  if(options.isMobile) {
    /* $level2.stop().animate({left: '-100%'}, 300, 'linear', function() {
      // Animation complete.
      $(this).fadeOut(500);
    });*/
    $level2.stop().fadeOut(0, 'linear');
  }else{
    $level2.stop().fadeOut(animationTime, 'linear');
  }

  $level2.attr('aria-hidden', true);

  options.activeSubButton = '';

};


const createMobileMenu = () => {

  if ($(options.primaryNav).length) {

    // append open button
    // $('<button type="button" id="mobile-nav-icon"  class="mobile-nav-icon">Open Menu</button>').insertAfter('#site-logo');
    if(!$('#mobile-nav-icon').length) {
      $('<button type="button" id="mobile-nav-icon"  class="mobile-nav-icon"><span class="visually-hidden">Open Menu</span><svg viewBox="0 0 64 64" width="20.00px" height="18px" role="img" aria-hidden="true" focusable="false"><use xlink:href="/Static/images/sprites/symbols.svg#burger" /></svg></button>').insertAfter('#site-logo');
    }

    // append close button
    // $('<button type="button" id="mobile-nav-icon-close"  class="mobile-nav-icon mobile-nav-icon--close">Close Menu</button>').appendTo('#primary-nav-outer');
    $('<button type="button" id="mobile-nav-icon-close"  class="mobile-nav-icon  mobile-nav-icon--close"><span class="visually-hidden">Close Menu</span><svg viewBox="0 0 9.899 9.899" width="9.899px" height="9.899px" role="img" aria-hidden="true" focusable="false"><use xlink:href="/Static/images/sprites/symbols.svg#close-cross" /></svg></button>').appendTo('#primary-nav-outer');

    // append open search button
    // $('<button type="button" id="mobile-search-icon" class="mobile-search-icon">Search Open</button>').insertAfter('#mobile-nav-icon');

    $(options.primaryNav).attr('aria-hidden', 'true');

    $(options.mobileNavIcon).each((index, el) => {

      let $this = $(el);

      // add ARIA attributes
      $this.attr('aria-controls', $(options.primaryNav).attr('ID'));
      $this.attr('aria-expanded', 'false');

      $this.on('click', (e) => {

        let state = $this.attr('aria-expanded') === 'false' ? true : false;
        $(options.mobileNavIcon).attr('aria-expanded', state);
        $(options.primaryNav).attr('aria-hidden', !state);
        $('html').toggleClass('nav-active');

        // set focus on the coresponding other open/close button
        let $primaryNavLink = $('a').not('#primary-nav-outer a');

        if ($(e.target).attr('id') === 'mobile-nav-icon') {

          // hide elements behind the nav overlay from focus
          $primaryNavLink
            .attr('tabindex', '-1')
            .attr('style', 'cursor:default;');
          $('button').not('#primary-nav-outer button').attr('disabled', 'true');
          $('input').not('#primary-nav-outer input').attr('disabled', 'true');

          $('#mobile-nav-icon-close').focus();

          $('#primary-nav').animate({left: 0}, 300);

        } else {
          // close button
          closeSubMenu(0);
          // restore focus to elements behind the nav overlay
          $primaryNavLink
            .attr('tabindex', '0')
            .attr('style', 'cursor:pointer;');
          $('button').not('#primary-nav-outer button').removeAttr('disabled');
          $('input').not('#primary-nav-outer input').removeAttr('disabled');

          $('#mobile-nav-icon').focus();

          $('#primary-nav').animate({left: '-100%'}, 300);

        }

      });

    });


    $('.nav-button').each((index, el) => {
      let $this = $(el);
      let linkText = $this.text();
      var $btn = $('<button />', {'class': 'nav-level-2__back-btn', text: linkText}).prependTo($this.next());
      $btn.attr('aria-label', 'Close');
      $btn.on('click', () => {
        closeSubMenu();
      });
    });

  }

  // clone and append secondary nav
  $('.secondary-nav__list:first').clone().addClass('secondary-nav__list--mobile').insertAfter('#primary-nav-list');
}

const destroyMobileMenu = () => {

  // remove secondary nav
  $('.secondary-nav__list--mobile').remove();

  // remove ARIA attribute on wrapper
  $(options.primaryNav).removeAttr('aria-hidden');

  // unbind click events
  $(options.mobileNavIcon).off('click');
  // $(options.mobileSearchIcon).off('click');

  // remove focus handling
  $('.nav-button, .nav-level-1__link, secondary-nav__link').off('focus');

  // remove mobile nav button
  $(options.mobileNavIcon).remove();
  // $(options.mobileSearchIcon).remove();

  // remove mobile back buttons
  $('.nav-level-2__back-btn').remove();

  // remove modal state
  $('html').removeClass('nav-active');

  // close any open subnavs
  $('.nav-button.is-active').click();

  // restore focus to elements behind the nav overlay
  $('a').not('#primary-nav-outer a').attr('tabindex', '0');
  // $('a').not('#primary-nav-outer a').attr('style', 'cursor:pointer;');
  $('button').not('#primary-nav-outer button').removeAttr('disabled');
  $('input').not('#primary-nav-outer input').removeAttr('disabled');
};






const createDesktopMenu = () => {

  // bind click event to the document to close any open sub-menus
  $('html').on('click, touchstart', (event) => {

    if ($(event.target).closest('#primary-nav').length === 0) {
      $('.nav-button.is-active').click();
    }
  });

  $(document).on('click', (event) => {
    event.stopPropagation();
    // check if the clicked area is dropDown or not
    if ($('#primary-nav').has(event.target).length === 0) {
      closeSubMenu();
      setMenuSolid(false);
    }
  });

};

const destroyDesktopMenu = () => {
  $('.nav-button.is-active').click();
  $('html').off('click');
};

export { init }