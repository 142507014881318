<template>
  <transition name="fade">
    <div class="basket-popup" v-if="popupVisible">
      <div class="basket-popup__alert">
        <button
          class="btn btn--close basket-popup__close-btn"
          :aria-label="closeAriaText"
          @click="close"
        ></button>
        <div class="basket-popup__tick"></div>
        <p>{{ alertMessageText }}</p>
        <p class="small">{{ holdItemText }}</p>
      </div>

      <div class="basket-popup__content">
        <div class="basket-popup__inner">
          <ol class="basket-popup__list">
            <li class="basket-popup__list-item">
              <div class="basket-popup__item">
                <div class="basket-popup__item-img-holder">
                  <img
                    :data-src="productAdded.ImageUrl"
                    alt=""
                    class="img-responsive lazyload"
                  />
                </div>

                <div class="basket-popup__item-content">
                  <p class="basket-popup__item-title">
                    {{ productAdded.Title }}
                  </p>
                  <div
                    class="star-rating"
                    role="img"
                    :aria-label="productAdded.FeefoStarRating.Summary"
                    v-if="productAdded.FeefoStarRating.FeefoStars"
                  >
                    <span
                      v-for="(i, index) in 5"
                      class="star-rating__star"
                      :class="[productAdded.FeefoStarRating.FeefoStars[index]]"
                      :key="index"
                      aria-hidden="true"
                    ></span>
                  </div>
                  <div class="basket-line" v-if="productAdded.TravelDate">
                    <div class="basket-line__img">
                      <svg
                        class="basket-line__svg"
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="20"
                        viewBox="0 0 18 20"
                      >
                        <use
                          xlink:href="/Static/images/sprites/symbols.svg#date"
                        />
                      </svg>
                    </div>
                    <p class="basket-line__text">
                      {{ productAdded.TravelDate }}
                    </p>
                  </div>
                  <template v-if="productAdded.PriceBreakDown">
                    <div
                      class="basket-line"
                      v-for="(item, i) in productAdded.PriceBreakDown"
                      :key="i"
                    >
                      <div class="basket-line__img">
                        <svg
                          class="basket-line__svg"
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                        >
                          <use
                            xlink:href="/Static/images/sprites/symbols.svg#person"
                          />
                        </svg>
                      </div>
                      <p class="basket-line__text">
                        {{ item.TicketTypeLabel }}
                      </p>
                      <p class="basket-line__cost">
                        {{ item.Count }} x
                        {{ formatCurrency(item.IndividualPrice).format() }}
                      </p>
                    </div>
                  </template>
                </div>
              </div>
            </li>
          </ol>

          <div class="basket-popup__total">
            <div class="basket-line">
              <p class="basket-line__text">{{ totalText }}</p>
              <p class="basket-line__cost">
                {{ formatCurrency(productAdded.TotalCost).format() }}
              </p>
            </div>
          </div>

          <div class="basket-popup__buttons">
            <a :href="viewBasketUrl" class="btn basket-popup__button">{{
              viewBasketText
            }}</a>
            <a
              :href="checkoutNowUrl"
              class="btn btn--alt basket-popup__button"
              >{{ checkoutNowText }}</a
            >
          </div>

          <p class="basket-line__text" v-if="clientInformation">
            {{ clientInformation }}
          </p>

          <div class="basket-popup__footer" v-if="relatedProducts.length > 0">
            <p class="basket-popup__footer-title">
              {{ relatedText }} - TODO!!!
            </p>

            <ol class="basket-popup__card-list">
              <li
                class="basket-popup__card-list-item"
                v-for="(product, productIndex) in relatedProducts"
                :key="productIndex"
              >
                <a :href="product.ProductUrl" class="card">
                  <div class="card__img-holder">
                    <img
                      :data-src="product.ImageUrl"
                      alt="product.Title"
                      class="card__img img-responsive lazyload"
                    />
                  </div>
                  <div class="card__content">
                    <div class="card__inner">
                      <h3 class="card__title">{{ product.Title }}</h3>
                    </div>
                    <div class="card__footer">
                      <div
                        class="star-rating"
                        role="img"
                        :title="productAdded.FeefoStarRating.Summary"
                        :aria-label="productAdded.FeefoStarRating.Summary"
                        v-if="productAdded.FeefoStarRating.FeefoStars"
                      >
                        <span
                          v-for="(i, index) in 5"
                          class="star-rating__star"
                          :class="[
                            productAdded.FeefoStarRating.FeefoStars[index],
                          ]"
                          :key="index"
                          aria-hidden="true"
                        ></span>
                      </div>
                    </div>
                  </div>
                </a>
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { ref, computed, watch, onMounted } from 'vue';
import { useStore } from 'vuex';
import { setCurrencyFormat, formatPriceCurrency } from '../../utils';

export default {
  name: 'BasketPopup',
  data: function () {
    return {
      currentCurrency: null,
    };
  },
  props: {
    store: {
      type: String,
      default: 'basket',
    },
    closeAriaText: String,
    alertMessageText: String,
    totalText: String,
    viewBasketText: String,
    viewBasketUrl: String,
    checkoutNowText: String,
    checkoutNowUrl: String,
    relatedText: String,
    clientInformation: String,
    currencyFormatInfo: null,
  },
  setup(props) {
    const currentCurrency = ref(null);

    const store = useStore();

    const productAdded = computed(() => store.state[props.store].productAdded);
    const holdItemText = computed(() => store.state[props.store].holdItemText);
    const relatedProducts = computed(
      () => store.state[props.store].relatedProducts,
    );
    const popupVisible = computed(() => productAdded.value !== null);

    const formatCurrency = formatPriceCurrency(currentCurrency);

    const close = () => {
      store.dispatch(`${props.store}/clearProductAdded`);
    };

    watch(popupVisible, val => {
      if (val) {
        document.body.classList.add('basket-open');
        document.documentElement.classList.add('basket-open');
      } else {
        document.body.classList.remove('basket-open');
        document.documentElement.classList.remove('basket-open');
      }
    });

    onMounted(() => {
      currentCurrency.value = value =>
        setCurrencyFormat(value, props.currencyFormatInfo);
    });

    return {
      productAdded,
      holdItemText,
      relatedProducts,
      popupVisible,
      formatCurrency,
      close,
    };
  },
};
</script>
