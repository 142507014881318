
export default {

  // set items
  setListingPageId(state, id) {
    state.ListingPageId = id;
  },



};