const init = () => {

  const options = {
    scrollBtn: '.js-scroll-top'
  };

  // click event to scroll the page to the top
  $(options.scrollBtn).click( () => {
    $('html, body').animate({
      scrollTop: 0
    }, 600, () => {
      // animation complete - shift focus body so the skip link is the first item to tab to
      $('body').attr('tabindex','-1').focus();
    });
    return false;
  });

  

};

export { init }