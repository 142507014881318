import { config } from '../../config';

export const isMobile = {

  data: function() {
    return {
      isMobile: false        
    };
  },

  created() {
    var self = this;
    enquire.register(`screen and (max-width: ${config.breakpoint.mdMax}px)`, {
      match: function () {
        self.isMobile = true;
      }
    });
    enquire.register(`screen and (min-width: ${config.breakpoint.lgMin}px)`, {
      match: function () {
        self.isMobile = false;
      }    
    });
  }

}