<template>
  <article class="card js-card">
    <div class="card__header">
      <h3 class="card__title card__title--large card__title--slim">
        {{ item.Title }}
      </h3>
    </div>
    <div class="card__img-holder card__img-holder--slim">
      <span
        class="card__tab"
        v-if="item.ProductType.Key"
        :class="item.ProductType.Key"
        >{{ item.ProductType.Value }}</span
      >
      <div class="card__roundal" v-if="item.DurationText">
        <svg
          class="card__roundal-image"
          role="img"
          aria-hidden="true"
          focusable="false"
        >
          <use
            xlink:href="/Static/images/sprites/symbols.svg#product-info-duration"
          ></use>
        </svg>
        <span class="card__roundal-text">{{ item.DurationText }}</span>
      </div>

      <picture>
        <source
          :srcset="item.FilterIndexImage.MdUrl"
          media="(min-width: 992px)"
        />
        <!-- 552x212-->
        <source
          :srcset="item.FilterIndexImage.SmUrl"
          media="(min-width: 768px)"
        />
        <!-- 324x212-->
        <source
          :srcset="item.FilterIndexImage.LgUrl"
          media="(min-width: 0px)"
        />
        <!-- 687x212-->
        <img
          :data-src="item.FilterIndexImage.MdUrl"
          :alt="item.FilterIndexImage.Alt"
          class="card__img img-responsive lazyload"
          data-object-fit="cover"
        />
      </picture>
    </div>
    <div class="card__content">
      <div class="card__inner">
        <div
          class="star-rating"
          role="img"
          :aria-label="item.FeefoStarRating.Summary"
          v-if="item.FeefoStarRating.FeefoStars"
        >
          <span
            v-for="(i, index) in 5"
            class="star-rating__star"
            :class="[item.FeefoStarRating.FeefoStars[index]]"
            :key="index"
            aria-hidden="true"
          ></span>
        </div>
        <div class="card__meta">
          <span class="card__price">{{ item.ConvertedFromPriceText }}</span>
          <span
            class="flag card__flag"
            :class="item.Flag.key"
            v-if="item.Flag"
            >{{ item.Flag.label }}</span
          >
        </div>
      </div>
      <a :href="item.Url" class="btn btn--full-width btn--thin card__btn">{{
        readMoreText
      }}</a>
    </div>
  </article>
</template>

<script>
export default {
  name: 'FilterIndexItem',
  props: ['item', 'readMoreText'],
};
</script>
