// import { config } from './../config';

const options = {
  getInTouchTrigger: '.js-get-in-touch-trigger',
};

const init = () => {

  $(options.getInTouchTrigger).each((index, el) => {
    let $this = $(el);
    let $getInTouchContent = $this.closest('.container-fluid').find('.js-get-in-touch-content');
    let buttonID = `get-in-touch-heading-${index}`;
    let contentID = `get-in-touch-content-${index}`;
    let $button;

    $button = $(`<button type="button" id="${buttonID}" aria-expanded="false" aria-controls="${contentID}">${$this.html()}</button>`);

    // Add a button inside the trigger element
    $this.html($button);

    $getInTouchContent
      .attr('ID', contentID)
      .attr('aria-labelledby', buttonID)
      .attr('aria-hidden', 'true');

    $button.on('click', () => {

      let state = $button.attr('aria-expanded') === 'false' ? true : false;

      $button
        .stop() // added to stop click events queuing up
        .attr('aria-expanded', state)
        .toggleClass('is-active');

      $getInTouchContent
        .stop() // added to stop click events queuing up
        .attr('aria-hidden', !state)
        .slideToggle();

      // scroll to the top of the form if opening (but not closing) the form
      if ( state === true ) {
        let targetOffset = $('#' + contentID).offset().top;
        $('html,body').animate({scrollTop: targetOffset});
      }

    });
  });
};

export { init }