const init = () => {

  $('.js-tour-highlights').on('click', function(e) {
    e.preventDefault();

    // open our gallery
    $('.product-detail-gallery').magnificPopup({
      delegate: 'a',
      type: 'image',
      tLoading: 'Loading image #%curr%...',
      mainClass: 'mfp-img-mobile',
      // autoFocusLast: false,
      focus: '.mfp-close',
      closeBtnInside: true,
      // closeMarkup: '<button title="%title%" type="button" class="mfp-close">Close </button>',
      gallery: {
        enabled: true,
        navigateByImgClick: true,
        preload: [0, 1] // Will preload 0 - before current, and 1 after the current image
      },
      image: {
        tError: '<a href="%url%">The image #%curr%</a> could not be loaded.',
        titleSrc: function(item) {
          return item.el.attr('title');
        }
      },
      callbacks: {
        // move the next and previous buttons
        buildControls: function() {
          if (this.items.length > 1) {
            this.contentContainer.append(this.arrowLeft.add(this.arrowRight));
          }
        }
      }
    }).magnificPopup('open');
  });



  $('.js-view-map').magnificPopup({
    type: 'image',
    autoFocusLast: false
  });


};

export {
  init
}