export default {
    
  // get data which will be saved in the URL
  // AND sent to the server
  urlData: state => {
    const urlData = {
      Take: state.Take
    };
    if (state.ListingPageId == null) {
      urlData.SearchTerm = state.SearchTerm,
      urlData.SortOrder = state.SortOrder
      urlData.Culture = state.Culture
    }
    return urlData;
  },

  // get data which will be sent to the server
  // NOT saved in the URL
  serverData: state => {
    const serverData = {
      From: state.From
    };
    if (state.ListingPageId) {
      serverData.ListingPageId = state.ListingPageId;
    }
    return serverData;
  }

};

