var sb;

const options = {
  trigger: '.js-featured-products',
};

const init = () => {

  $(options.trigger).each((index, el) => {
    let $this = $(el);
    let viewport = $this.find('.featured-products__list-container').get(0);
    sb = new ScrollBooster({
      viewport,
      mode: 'x',
      textSelection: false,
      onUpdate: (data)=> {
        viewport.scrollLeft = data.position.x
      },
      onClick: (data, event) => {
        if (Math.abs(data.dragOffsetPosition.x) > 5) {
          event.stopPropagation();
          event.preventDefault();
        }
      }         
    });
    $(window).on('resize', function () {
      let $container = $this.find('.container-fluid');
      let leftEdge = parseInt($container.css('margin-left')) + parseInt($container.css('padding-left'));
      let rightEdge = parseInt($container.css('margin-right')) + parseInt($container.css('padding-right'));
      $this.find('.featured-products__item:first-child').css('padding-left', leftEdge);
      $this.find('.featured-products__item:last-child').css('padding-right', rightEdge);
      sb.updateMetrics()
    }).resize(); // Invoke the resize event immediately
  });

};



export { init }