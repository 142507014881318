export default {
  isBusy: false,
  errorStatus: false,
  // errorTitle: null,
  // errorDescription: null,
  basketReservationId: null,
  productToEdit: null,
  productToRemove: null,
  basket: null,
  subTotal: null,
  total: null,
  promodCodes: null,
  count: null,
  productAdded: null,
  productRemoved: false,
  productEdited: false,
  holdItemText: '',
  relatedProducts: [],
  discountApplied: null
}

