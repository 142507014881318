const init = () => {
  jQuery.validator.addMethod(
    'regex',
    function (value, element, regexp) {
      if (regexp.constructor != RegExp) regexp = new RegExp(regexp);
      else if (regexp.global) regexp.lastIndex = 0;
      return this.optional(element) || regexp.test(value);
    },
    'erreur expression reguliere',
  );

  jQuery.validator.addMethod(
    'phone',
    function (value, element) {
      return this.optional(element) || /^(\+?\d[\d\s]*){1,31}$/.test(value);
    },
    'Invalid phone number format',
  );

  jQuery.validator.addMethod(
    'email',
    function (value, element) {
      return (
        this.optional(element) ||
        /^[^\s@]+@[^\s@]+\.[a-zA-Z0-9][^\s@]*$/.test(value)
      );
    },
    'Invalid email format',
  );

  $('#checkout-form').validate({
    rules: {
      countrySelect: {
        required: function () {
          return !$('#country-input').hasClass('valid');
        },
      },
      FirstName: {
        required: true,
        regex: /^[a-zA-Z][\sa-zA-Z,'‘’-]*[a-zA-Z']$/,
        maxlength: 60,
      },
      LastName: {
        required: true,
        regex: /^[a-zA-Z][\sa-zA-Z,'‘’-]*[a-zA-Z']$/,
        maxlength: 60,
      },
      Email: {
        required: true,
        email: true,
        maxlength: 64,
      },
      PhoneNumber: {
        required: true,
        phone: true,
      },
      AcceptTerms: {
        required: true,
      },
      CountryFreeText: {
        regex: /^(?!\s*$)[a-zA-Z\s]*$/,
        maxlength: 50,
      },
    },
    errorElement: 'div',
    errorPlacement: function (error, element) {
      error.addClass('invalid-feedback');

      $('#checkout-form').addClass('was-validated');

      if (element.prop('type') === 'checkbox') {
        error.insertAfter(element.parent().children('label'));
      } else {
        error.insertAfter(element);
      }
    },
  });

  // Add event listener to trim spaces on blur
  $('#checkout-form input').on('blur', function () {
    $(this).val($.trim($(this).val()));
  });

  // label flag for terms
  $('#checkout-form').submit(function () {
    if ($(this).valid()) {
      // get form name
      let formName = $('#FormName').val();
      // console.log(formName);

      dataLayer.push({
        event: 'formSubmit',
        formName: formName,
      });
    }
  });
};

export { init };
