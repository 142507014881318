const init = () => {

  // we need to apply a focusin/focusoutto cards due to the way the links are applied
  // we are attaching a class which in turn allows us to hook up some CSS
  $( '.js-card' ).focusin(function() {
    $(this).addClass('on-focus');
  });

  $( '.js-card' ).focusout(function() {
    $(this).removeClass('on-focus');
  });


  // Let the document know when the mouse is being used
  // we can then use this class to hide outlines if needed
  document.body.addEventListener('mousedown', function() {
    document.body.classList.add('using-mouse');
  });

  document.body.addEventListener('keydown', function() {
    document.body.classList.remove('using-mouse');
  });

  // lazyload picturefill background images
  new lazyloadPicturefillBackground();

};

export { init }