<template>
  <div class="booking-modal__calendar clndr" :id="id"></div>
</template>

<script>
import { onMounted, watch, ref } from 'vue';

export default {
  name: 'Calendar',
  props: {
    availablityData: Array,
    selectedDay: null,
    id: String,
  },
  setup(props, { emit }) {
    const clndr = ref(null);

    const highlightSelectedDay = () => {
      $('.clndr .day.selected').removeClass('selected');
      const date = moment(props.selectedDay).format('YYYY-MM-DD');
      $(`.clndr .calendar-day-${date}`).addClass('selected');
    };

    const availablityData = ref(props.availablityData);

    watch(
      () => props.selectedDay,
      () => {
        console.log('selectedDay watch');
        highlightSelectedDay();
      },
    );

    watch(
      () => props.availablityData,
      () => {
        availablityData.value = props.availablityData;
        clndr.value.setEvents(availablityData.value);
      },
    );

    onMounted(() => {
      const today = moment();

      clndr.value = $(`#${props.id}`).clndr({
        template: `
            <div id="booking-calendar-template">
              <div class="clndr__controls">
                <button
                  class="clndr__button clndr__button--previous clndr-previous-button"
                >
                  <span class="visually-hidden">Previous</span>
                </button>
                <span class="clndr__month"><%= month %> <%= year %></span>
                <button class="clndr__button clndr-next-button">
                  <span class="visually-hidden">Next</span>
                </button>
              </div>
              <div class="clndr__clndr__grid">
                <div class="clndr__header-days">
                  <% _.each(daysOfTheWeek, function(day) { %>
                  <span class="clndr__header-day"><%= day %></span>
                  <% }); %>
                </div>
                <div class="clndr__days">
                  <% _.each(days, function(day) { %>
                  <button class="<%= day.classes %>" id="<%= day.id %>">
                    <span class="clndr__day-number"><%= day.day %></span>
                  </button>
                  <% }); %>
                </div>
              </div>
            </div>
          `,
        weekOffset: 1,
        startWithMonth: moment(props.selectedDay),
        showAdjacentMonths: false,
        ignoreInactiveDaysInSelection: true,
        constraints: {
          startDate: today,
          endDate: moment().add(2, 'years').format('YYYY-MM-DD'),
        },
        clickEvents: {
          click: function (target) {
            if (
              $(target.element).hasClass('event') &&
              !$(target.element).hasClass('inactive')
            ) {
              emit('onDaySelected', target.date);
            } else {
              console.log('That date is outside of the range.');
            }
          },
          onMonthChange: function (month) {
            console.log('you just went to ' + month.format('MMMM, YYYY'));
            emit('onMonthChange', month);
          },
        },
        doneRendering: function () {
          highlightSelectedDay();
        },
      });
    });

    return {
      clndr,
      highlightSelectedDay,
      availablityData,
    };
  },
};
</script>
