<template>
  <div class="product-filter">
    <div class="filter">
      <div class="filter__header">
        <button
          class="btn btn--plain-text filter__clear"
          @click="clearAllFilters"
          :disabled="!categories.length"
        >
          {{ filterClearText }}
        </button>
        <button
          class="btn btn--close filter__close"
          @click="hideMobileFilters"
          :aria-label="filterCloseText"
        ></button>
      </div>

      <div class="filter__container" id="filter-container">
        <slot></slot>

        <range-slider :store="store" :label="priceFilterText" />

        <div
          class="accordion-panel filter-item"
          v-for="(filter, filterKey) in filters"
          :key="filterKey"
        >
          <h2
            class="accordion-panel__heading js-accordion-trigger filter-item__title"
            data-accordian-type="multi"
          >
            {{ filter.Label }}
          </h2>
          <div
            class="accordion-panel__content js-accordion-target filter-item__options"
          >
            <ul class="filter-list">
              <li
                class="filter-list__item"
                v-for="(item, itemKey) in filter.Children"
                :key="itemKey"
              >
                <div class="styled-checkbox">
                  <label>
                    <input
                      type="checkbox"
                      :checked="item.Selected"
                      @click="toggleFilterValue(filterKey, itemKey, $event)"
                    />
                    <span class="filter-list__label"
                      >{{ item.Label
                      }}<span class="filter-list__count"
                        >({{ item.Count }})</span
                      ></span
                    >
                  </label>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, nextTick, ref, watch } from 'vue';
import { useStore } from 'vuex';
import EventBus from '@/vue/EventBus';
import * as accordion from '../../modules/accordion';
import RangeSlider from './RangeSlider.vue';

export default {
  name: 'Filters',

  props: {
    store: {
      type: String,
      required: true,
    },
    filterText: String,
    filterCloseText: String,
    filterClearText: String,
    resultsText: String,
    priceFilterText: String,
  },

  components: {
    RangeSlider,
  },

  setup(props) {
    const store = useStore();
    const filters = ref([]);
    const categories = ref([]);

    store.subscribe((mutation, state) => {
      if (mutation.type === `${props.store}/setFilters`) {
        filters.value = state[props.store].filters;
      }
      if (mutation.type === `${props.store}/setCategories`) {
        categories.value = state[props.store].Categories;
      }
    });

    const toggleFilterValue = (filterKey, itemKey, e) => {
      store.dispatch(`${props.store}/toggleFilterValue`, {
        filterKey: filterKey,
        itemKey: itemKey,
        checked: e.target.checked,
      });
      store.dispatch(`${props.store}/fetchItems`);
    };

    const clearAllFilters = () => {
      store.dispatch(`${props.store}/clearAllFilters`);
      store.dispatch(`${props.store}/fetchItems`);
    };

    const viewMobileFilters = () => {
      document.querySelector('html').classList.add('filter-open');
      document.querySelector('.filter__close').focus();
    };

    const hideMobileFilters = () => {
      document.querySelector('html').classList.remove('filter-open');
      document.querySelector('.display-filter-btn').focus();
    };

    watch(
      () => filters.value,
      (newValue, oldValue) => {
        if (newValue.length !== oldValue.length) {
          accordion.init();
        }
      },
      { flush: 'post' },
    );

    // TODO: replace EventBus
    // onMounted(() => {
    //   nextTick().then(() => {
    //     EventBus.$once('ITEMS_LOADED', () => {
    //       picturefill();
    //       new lazyloadPicturefillBackground();
    //     });
    //   });
    // });

    return {
      filters,
      categories,
      toggleFilterValue,
      clearAllFilters,
      viewMobileFilters,
      hideMobileFilters,
    };
  },
};
</script>
