<template>
  <article class="content-card js-card">
    <div class="content-card__main">
      <h2 class="content-card__title">
        <a :href="item.Url" class="content-card__link">{{ item.Title }}</a>
      </h2>
      <p class="content-card__intro">{{ item.Description }}</p>
      <div class="content-card__details">
        <div
          class="content-card__author-image-container"
          v-if="item.AuthorImage && item.AuthorImage.Url != ''"
        >
          <img
            :data-src="item.AuthorImage.Url"
            :alt="item.AuthorImage.Alt"
            class="content-card__author-image lazyload"
            data-object-fit="cover"
          />
        </div>
        <div class="content-card__details-text">
          <span v-if="item.AuthorName" class="content-card__author-title"
            >{{ item.AuthorName }}, {{ item.AuthorOccupation }}</span
          >
          <div class="content-card__meta">
            <time class="content-card__date" datetime="2019-04-18">{{
              item.PublishedDateText
            }}</time
            ><span class="content-card__read-time">{{
              item.EstimatedReadTime
            }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="content-card__image-container">
      <picture class="content-card__picture" v-if="item.FilterIndexImage">
        <source
          :srcset="item.FilterIndexImage.MdUrl"
          media="(min-width: 992px)"
        />
        <!-- 552x212 -->
        <source
          :srcset="item.FilterIndexImage.SmUrl"
          media="(min-width: 768px)"
        />
        <!-- 436x212-->
        <source
          :srcset="item.FilterIndexImage.LgUrl"
          media="(min-width: 0px)"
        />
        <!-- 687x212-->
        <img
          :data-src="item.FilterIndexImage.Url"
          :alt="item.FilterIndexImage.Alt"
          class="content-card__image img-responsive lazyload"
          data-object-fit="cover"
        />
      </picture>
    </div>
  </article>
</template>

<script>
export default {
  name: 'ContentIndexItem',
  props: ['item'],
};
</script>
