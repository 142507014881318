// capitalize first letter of string
const capitalize = value => {
  if (!value) {
    return;
  }
  value = value.toString();
  return value.charAt(0).toUpperCase() + value.slice(1);
};

const setCurrencyFormat = (value, currencyFormatInfo) =>
  currency(value, {
    symbol: currencyFormatInfo.CurrencySymbol,
    separator: currencyFormatInfo.CurrencyGroupSeparator,
    decimal: currencyFormatInfo.CurrencyDecimalSeparator,
    precision: currencyFormatInfo.CurrencyDecimalDigits,
    pattern: currencyFormatInfo.CurrencyPositivePattern,
    negativePattern: currencyFormatInfo.CurrencyNegativePattern,
    formatWithSymbol: true,
  });

const formatPriceCurrency = currentCurrency => price => {
  let totalPrice = currentCurrency.value(0);
  totalPrice = currentCurrency.value(price);
  return totalPrice;
};

export { capitalize, setCurrencyFormat, formatPriceCurrency };
