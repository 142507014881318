<template>
  <div class="basket">
    <transition name="fade">
      <div class="alert" v-if="productRemoved">
        <div class="alert__tick"></div>
        <p class="alert__text">
          Product has been successfully removed from your basket
        </p>
      </div>
    </transition>

    <div class="container-fluid">
      <transition name="fade" mode="out-in">
        <div v-if="errorStatus == true" key="1">
          <h1 class="basket-page__title">{{ errorTitleText }}</h1>
          <p class="basket-page__empty-message">{{ errorDescriptionText }}</p>
        </div>

        <div v-else-if="this.basket != null && this.basket.length == 0" key="2">
          <h1 class="basket-page__title">{{ titleText }}</h1>
          <p class="basket-page__empty-message">{{ emptyText }}</p>
        </div>

        <div
          class="basket-page__main"
          v-else-if="this.basket != null && this.basket.length > 0"
          key="3"
        >
          <h1 class="basket-page__title">{{ titleText }}</h1>

          <div class="row">
            <div class="col-lg-8">
              <transition-group name="fade" tag="ol" class="card-list">
                <li
                  class="card-list__item card-list__item--two-col"
                  v-for="product in basket"
                  :key="product.ReservationReference"
                >
                  <article class="card">
                    <div class="card__content">
                      <div class="card__inner">
                        <h3 class="card__title card__title--slim">
                          {{ product.Title }}
                        </h3>
                        <div
                          class="star-rating"
                          role="img"
                          :title="product.FeefoStarRating.Summary"
                          :aria-label="product.FeefoStarRating.Summary"
                          v-if="product.FeefoStarRating.FeefoStars"
                        >
                          <span
                            v-for="(i, index) in 5"
                            class="star-rating__star"
                            :class="[product.FeefoStarRating.FeefoStars[index]]"
                            :key="index"
                            aria-hidden="true"
                          ></span>
                        </div>
                      </div>
                    </div>
                    <div class="card__img-holder card__img-holder--slim">
                      <img
                        :data-src="product.ImageUrl"
                        alt="@item.Image.Alt"
                        class="card__img img-responsive lazyload"
                      />
                    </div>
                    <div class="card__content">
                      <div class="card__inner">
                        <div class="basket-line" v-if="product.TravelDate">
                          <div class="basket-line__img">
                            <svg
                              class="basket-line__svg"
                              xmlns="http://www.w3.org/2000/svg"
                              width="18"
                              height="20"
                              viewBox="0 0 18 20"
                            >
                              <use
                                xlink:href="/Static/images/sprites/symbols.svg#date"
                              />
                            </svg>
                          </div>
                          <p class="basket-line__text">
                            {{ product.TravelDate }}
                          </p>
                        </div>

                        <template v-if="product.PriceBreakDown">
                          <div
                            class="basket-line"
                            v-for="(item, i) in product.PriceBreakDown"
                            :key="i"
                          >
                            <div class="basket-line__img">
                              <svg
                                class="basket-line__svg"
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                              >
                                <use
                                  xlink:href="/Static/images/sprites/symbols.svg#person"
                                />
                              </svg>
                            </div>

                            <p class="basket-line__text">
                              {{ item.TicketTypeLabel }}
                            </p>

                            <p class="basket-line__cost">
                              {{ item.Count }} x
                              {{
                                formatCurrency(item.IndividualPrice).format()
                              }}
                            </p>
                          </div>
                        </template>
                      </div>
                      <div class="basket-item-buttons">
                        <button
                          class="btn btn--thin btn--alert-icon remove-btn"
                          @click="removeProduct(product)"
                        >
                          Remove
                        </button>
                        <button
                          class="btn btn--thin btn--alt4 edit-btn"
                          @click="editProduct(product)"
                        >
                          Edit
                        </button>
                      </div>
                    </div>
                  </article>
                </li>
              </transition-group>
            </div>

            <div class="col-lg-4">
              <aside>
                <h2 class="basket__summary-title">{{ summaryText }}</h2>

                <div
                  class="basket__summary-product"
                  v-for="product in basket"
                  :key="product.ReservationReference"
                >
                  <h3
                    class="basket__summary-product-title"
                    v-if="basket.length > 1"
                  >
                    {{ product.Title }}
                  </h3>

                  <div
                    class="basket-line"
                    v-for="(item, i) in product.PriceBreakDown"
                    :key="i"
                  >
                    <div class="basket-line__img">
                      <svg
                        class="basket-line__svg"
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                      >
                        <use
                          xlink:href="/Static/images/sprites/symbols.svg#person"
                        />
                      </svg>
                    </div>
                    <p class="basket-line__text">{{ item.TicketTypeLabel }}</p>
                    <p class="basket-line__cost">
                      {{ item.Count }} x
                      {{ formatCurrency(item.IndividualPrice).format() }}
                    </p>
                  </div>
                </div>

                <div class="basket-total">
                  <div class="basket-line">
                    <p class="basket-line__text">{{ totalText }}</p>
                    <p
                      v-if="discountApplied"
                      class="basket-line__cost basket-line__cost--large"
                      :class="{
                        'basket-line__cost--strikeout': discountApplied,
                      }"
                    >
                      {{ subTotalPrice.format() }}
                    </p>
                    <p
                      class="basket-line__cost basket-line__cost--large"
                      :class="{ 'basket-line__cost--red': discountApplied }"
                    >
                      {{ totalPrice.format() }}
                    </p>
                  </div>
                </div>

                <promo-code
                  :enter-code-text="enterCodeText"
                  :apply-text="applyText"
                  :invalid-code-text="invalidCodeText"
                ></promo-code>

                <a
                  :href="secureCheckoutLink"
                  class="btn btn--thin btn--alt btn--full-width btn--lock-icon basket__checkout-btn"
                  :rel="secureCheckoutNofollow ? 'nofollow' : false"
                  >{{ secureCheckoutText }}</a
                >
                <a
                  :href="continueShoppingLink"
                  class="btn btn--thin btn--full-width basket__contine-btn"
                  :rel="continueShoppingNofollow ? 'nofollow' : false"
                  >{{ continueShoppingText }}</a
                >
              </aside>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import { setCurrencyFormat, formatPriceCurrency } from '../../utils';
import PromoCode from './PromoCode.vue';

export default {
  name: 'Basket',
  props: {
    store: {
      type: String,
      default: 'basket',
    },
    titleText: String,
    emptyText: String,
    summaryText: String,
    totalText: String,
    enterCodeText: String,
    applyText: String,
    invalidCodeText: String,
    secureCheckoutText: String,
    secureCheckoutLink: String,
    continueShoppingText: String,
    continueShoppingLink: String,
    errorTitleText: String,
    errorDescriptionText: String,
    currencyFormatInfo: null,
    secureCheckoutNofollow: Boolean,
    continueShoppingNofollow: Boolean,
  },
  components: {
    PromoCode,
  },
  setup(props) {
    const store = useStore();

    const currentCurrency = ref(null);

    const productRemoved = computed(
      () => store.state[props.store].productRemoved,
    );
    const basket = computed(() => store.state[props.store].basket);
    const errorStatus = computed(() => store.state[props.store].errorStatus);
    const total = computed(() => store.state[props.store].total);
    const subTotal = computed(() => store.state[props.store].subTotal);
    const discountApplied = computed(
      () => store.state[props.store].discountApplied,
    );
    const totalPrice = computed(() => currentCurrency.value(total.value));
    const subTotalPrice = computed(() => currentCurrency.value(subTotal.value));

    const formatDate = date => moment(date).format();

    const removeProduct = product => {
      removeAlert();
      store.dispatch(`${props.store}/setProductToRemove`, product);
    };

    const editProduct = product => {
      removeAlert();
      store.dispatch(`${props.store}/setProductToEdit`, product);
    };

    const removeAlert = () => {
      store.dispatch(`${props.store}/setProductRemoved`, false);
    };

    const formatCurrency = formatPriceCurrency(currentCurrency);

    onMounted(() => {
      currentCurrency.value = value =>
        setCurrencyFormat(value, props.currencyFormatInfo);
      if (basket.value === null) {
        store.dispatch(`${props.store}/loadBasket`);
      }
    });

    return {
      productRemoved,
      basket,
      errorStatus,
      totalPrice,
      subTotalPrice,
      discountApplied,
      formatDate,
      removeProduct,
      editProduct,
      formatCurrency,
    };
  },
};
</script>
