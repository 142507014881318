const init = () => {
  // When at least one other checkbox other than the "Necessary" checkbox is checked and "OK" is clicked
  window.addEventListener(
    'CookiebotOnAccept',
    function() {
      if (Cookiebot.consent.preferences) {
        dataLayer.push({
          event: 'cookieconsent_preferences',
          preferencesStatus: 'true'
        });
      }
      if (Cookiebot.consent.statistics) {
        dataLayer.push({
          event: 'cookieconsent_statistics',
          statisticsStatus: 'true'
        });
      }
      if (Cookiebot.consent.marketing) {
        // enable fitvid on youtube embed video
        setTimeout(function() {
          $('.js-responsive-video').fitVids();
        }, 2000);
        dataLayer.push({
          event: 'cookieconsent_marketing',
          marketingStatus: 'true'
        });
      }
    },
    false
  );

  // Everything unticked apart from 'Necessary'
  window.addEventListener(
    'CookiebotOnDecline',
    function() {
      dataLayer.push({
        event: 'cookieconsent_declineAll',
        preferencesStatus: 'false',
        statisticsStatus: 'false',
        marketingStatus: 'false'
      });
    },
    false
  );
};

export { init };
