<template>
  <form role="search" class="form site-search" @submit.prevent="search">
    <label :for="id" class="visually-hidden">{{ label }}</label>
    <input
      :id="id"
      type="search"
      :placeholder="label"
      v-model="SearchTerm"
      data-tsat="t06-site-search"
      class="site-search__term"
      @keyup="handleSearchInput"
    />
    <input
      type="submit"
      value="Search"
      data-tsat="t06-site-search-btn"
      class="btn site-search__btn"
    />
  </form>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import debounce from 'lodash/debounce';

export default {
  name: 'Search',
  props: {
    store: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: 'Search',
    },
    onChange: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const store = useStore();
    const id = `search${props._uid}`;
    const SearchTerm = computed({
      get: () => store.state[props.store].SearchTerm,
      set: term => store.commit(`${props.store}/setSearchTerm`, term),
    });

    const searchDebounced = debounce(() => {
      search();
    }, 500);

    const search = () => {
      store.dispatch(`${props.store}/fetchItems`);
    };

    const handleSearchInput = e => {
      if (props.onChange && e.keyCode !== 13) {
        searchDebounced();
      }
    };

    return {
      id,
      searchInput,
      SearchTerm,
      handleSearchInput,
      search,
    };
  },
};
</script>
