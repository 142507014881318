<template>
  <div class="content-index">
    <div v-if="currentPageId != null" class="content-index-header">
      <h1 class="content-index-title">{{ titleText }}</h1>
      <p class="content-index-intro">{{ introText }}</p>
    </div>

    <template v-else>
      <div class="preferences-bar">
        <div class="result-types">
          <a :href="productSearchPageUrl" class="result-types__link">{{
            productsLinkText
          }}</a>
          <span class="result-types__current">{{ articlesLinkText }}</span>
        </div>
        <Filtr :store="store" name="SortOrder" :label="sortText" />

        <div class="filter-intro filter-intro--content">
          <h1 class="filter-intro__title" v-if="DisplayText">
            {{ DisplayText }}
          </h1>
          <p class="filter-intro__subtitle" v-if="resultsFoundText">
            {{ items.length }} {{ resultsFoundText }}
          </p>
        </div>
      </div>
    </template>

    <ol class="card-list content-index__card-list">
      <li
        class="card-list__item card-list__item--two-col content-index__card-list-item"
        v-for="item in items"
        :key="item.Id"
      >
        <content-index-item :item="item"> </content-index-item>
      </li>
    </ol>

    <button
      class="btn btn--alt2 btn--centre load-more-btn"
      v-if="itemsRemaining > 0"
      @click="loadMore"
    >
      {{ loadMoreButtonText }}
    </button>
    <p class="filter-intro__all-loaded" v-else>{{ noMoreResultsText }}</p>
  </div>
</template>

<script>
import { onMounted, watch, nextTick } from 'vue';
import ResultsList from './ResultsList.vue';
import EventBus from '@/vue/EventBus';
import * as card from '../../modules/card.js';
import * as helper from '../../modules/helpers.js';

import { isMobile } from '../mixins/isMobile.js';

export default {
  ...ResultsList,
  name: 'ContentIndex',
  mixins: [isMobile],

  props: {
    ...ResultsList.props,
    store: {
      type: String,
      default: 'content-index',
    },
    currentPageId: Number,
    resultsText: String,
    resultsFoundText: String,
    sortText: String,
    titleText: String,
    introText: String,
    productSearchPageUrl: String,
    allResultsText: String,
    productsLinkText: String,
    articlesLinkText: String,
    noMoreResultsText: String,
  },

  setup(props) {
    const {
      items,
      itemsRemaining,
      Take,
      SearchTerm,
      Tag,
      CurrentSearch,
      DisplayText,
      urlData,
      updateSearch,
      loadMore,
    } = ResultsList.setup(props);

    onMounted(() => {
      if (props.currentPageId != null) {
        store.commit(`${props.store}/setListingPageId`, props.currentPageId);
      }
      // TODO: replace EventBus
      // nextTick().then(() => {
      //   EventBus.$on('ITEMS_LOADED', () => {
      //     console.log('content index emit bus on');
      //     card.init();
      //     helper.init();
      //     picturefill();
      //     new lazyloadPicturefillBackground();
      //   });
      // });
    });

    return {
      items,
      itemsRemaining,
      Take,
      SearchTerm,
      Tag,
      CurrentSearch,
      DisplayText,
      urlData,
      updateSearch,
      loadMore,
    };
  },
};
</script>
