export default {
  fetchItems({ commit, getters, dispatch }, replaceUrl) {
    let postData = {
      ...getters.urlData,
      ...getters.serverData,
    };

    fetch(getters.apiUrl('url'), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(postData),
    })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error('Network response was not ok');
        }
      })
      .then(data => {
        dispatch('setStateData', data);
        commit('setCurrentSearch', postData.SearchTerm);

        const query = new URLSearchParams(getters.urlData);
        const oldUrl = `${location.origin}${location.pathname}${location.search}`;
        const newUrl = `${location.origin}${
          location.pathname
        }?${query.toString()}`;

        if (oldUrl !== newUrl) {
          if (replaceUrl) {
            history.replaceState(null, null, newUrl);
          } else {
            history.pushState(null, null, newUrl);
          }
        }
      })
      .catch(error => {
        console.log('fail: ' + JSON.stringify(error, null, 2));
      });
  },

  setStateData({ commit }, data) {
    commit('setItems', data.Result.Items);
    commit('setItemsRemaining', data.Result.Remaining);
    commit('setItemsCount', data.Result.Total);
    commit('setPriceRangeMinPrice', data.PriceRange.MinPrice);
    commit('setPriceRangeMaxPrice', data.PriceRange.MaxPrice);
    commit('setPriceRangeBarHeights', data.PriceRange.BarHeights);
    commit('setDisplayText', data.DisplayText);
    commit('setCurrencyFormatInfo', data.PriceRange.CurrencyFormatInfo);

    if (data.Filters && data.Filters[0]) {
      commit('setFilters', data.Filters);
    }
  },

  fetchSortOrderOptions({ getters, state, commit }) {
    fetch(getters.apiUrl('urlSortOrder'))
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error('Network response was not ok');
        }
      })
      .then(data => {
        if (!state.SortOrder) {
          commit('setSortOrder', data[0].Key);
        }
        commit('setSortOrderOptions', data);
      })
      .catch(error => {
        console.log('fail: ' + JSON.stringify(error, null, 2));
      });
  },

  // clear all the filters
  clearAllFilters({ state, commit }) {
    // reset the take (to initial value) when a filter changes
    commit('setTake', state.initialTake);
    // update the filters data
    commit('setAllFiltersSelected', false);
    // empty the categories array
    commit('setCategories', []);
  },

  // toggle a single filter
  toggleFilterValue({ state, commit }, data) {
    // reset the take (to initial value) when a filter changes
    commit('setTake', state.initialTake);
    // update the filters data
    commit('setFilterSelected', data);
    // add/remove filter from category array
    const filter = state.filters[data.filterKey].Children[data.itemKey];
    if (data.checked) {
      commit('addCategory', filter.Id);
    } else {
      commit('removeCategory', filter.Id);
    }
  },

  // toggle a single filter
  setMinPrice({ state, commit }, data) {
    // reset the take (to initial value) when a filter changes
    commit('setTake', state.initialTake);
    commit('setMinPrice', data);
  },

  // toggle a single filter
  setMaxPrice({ state, commit }, data) {
    // reset the take (to initial value) when a filter changes
    commit('setTake', state.initialTake);
    commit('setMaxPrice', data);
  },
};
