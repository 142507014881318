export default {
  Culture: '',
  SearchTerm: '',
  CurrentSearch: '',
  SortOrder: '',
  SortOrderOptions: [],
  Tag: '',
  Categories: [],
  filters: [],
  ShowFilters: true,
  items: [],
  itemsRemaining: 0,
  itemsCount: 0,
  From: 0,
  Take: 0,
  initialTake: 0,
  parentId: null,
  // childItemsOnly: false,
  isSiteSearch: false,
  url: '/umbraco/api/productsearchapi/items',
  urlSortOrder: '/umbraco/api/productsearchapi/sortOrders',
  urlTagSearch: '/umbraco/api/tagresultssearch/items',
  PriceRangeBarHeights: [],
  PriceRangeMinPrice: null,
  PriceRangeMaxPrice: null,
  MinPrice: null,
  MaxPrice: null,
  DisplayText: '',
  currencyFormatInfo: null
}