<template>
  <div
    class="modal booking-modal"
    :id="modalId"
    tabindex="-1"
    role="dialog"
    aria-label="Booking form"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <button
          type="button"
          class="modal-close"
          data-dismiss="modal"
          aria-label="Close"
        ></button>

        <div class="modal-body">
          <div class="booking-widget">
            <template v-if="showError">
              <h2 class="booking-widget__error-title">{{ errorTitleText }}</h2>
              <p class="booking-widget__error-body">
                {{ errorDescriptionText }}
              </p>
              <div class="booking-widget__error-button-wrap">
                <button
                  class="btn btn--alt booking-widget__error-button"
                  data-dismiss="modal"
                >
                  {{ errorButtonText }}
                </button>
              </div>
            </template>

            <template v-else-if="productData != null">
              <div class="booking-widget__body">
                <section class="booking-widget__section">
                  <h4 class="booking-widget__section-title" v-if="productTitle">
                    {{ productTitle }}
                  </h4>
                  <h4 class="booking-widget__section-title">
                    {{ selectPartyText }}
                  </h4>

                  <div
                    class="form__group"
                    v-for="productType in productTypeDetails()"
                    :key="productType.Type"
                  >
                    <spinner
                      :label="getProductTypeLabel(productType.Type)"
                      :id="productType.Type"
                      :value="getProductTypeInitialValue(productType.Type)"
                      :min="parseInt(productType.MinQuantity) || undefined"
                      :max="parseInt(productType.MaxQuantity) || undefined"
                      @input="updateQty(productType.Type, $event)"
                    ></spinner>
                    <p
                      class="booking-widget__note"
                      v-if="productType.Description"
                    >
                      {{ productType.Description }}
                    </p>
                  </div>
                </section>

                <section
                  class="booking-widget__section"
                  v-if="availability_enabled"
                >
                  <h4 class="booking-widget__section-title">
                    {{ selectDateText }}
                  </h4>
                  <calendar
                    :id="
                      editMode == false && activeSubUmbracoId
                        ? 'booking-calendar-' + activeSubUmbracoId
                        : 'booking-calendar'
                    "
                    :availablityData="calendarAvailability()"
                    :selectedDay="selectedDay"
                    @onDaySelected="toggleDay($event)"
                    @onMonthChange="updateAvailabiltyDataForMonth($event)"
                  ></calendar>
                </section>

                <section
                  class="booking-widget__section"
                  v-if="
                    currentMonthContainsSelectedDay() == true &&
                    slotsForSelectedDay().length > 0
                  "
                >
                  <h4 class="booking-widget__section-title">
                    {{ selectTimeText }}
                  </h4>
                  <fieldset class="form__group">
                    <div
                      class="custom-radio"
                      v-for="slot in slotsForSelectedDay()"
                      :key="'radio' + slot.availability_id + '_' + modalId"
                    >
                      <input
                        type="radio"
                        :id="'radio' + slot.availability_id + '_' + modalId"
                        class="custom-radio__input"
                        :value="slot.availability_id"
                        v-model="selectedTimeslotId"
                        :disabled="!slotHasCapacity(slot)"
                      />
                      <label
                        class="custom-radio__label"
                        :for="'radio' + slot.availability_id + '_' + modalId"
                      >
                        {{ formatTime(slot.availability_from_date_time) }}
                      </label>
                    </div>
                  </fieldset>
                </section>
                <div class="booking-widget__basket">
                  <ul class="booking-widget__basket-list" v-if="selectedDay">
                    <li
                      class="booking-widget__basket-item"
                      v-for="productType in party"
                      :key="productType.type"
                    >
                      <span class="booking-widget__basket-item-title">
                        {{ getProductTypeLabel(productType.type) }}
                      </span>
                      <span class="booking-widget__basket-price">
                        {{ productType.count }} x
                        <span v-if="!QuantityPricing">{{
                          getProductTypePrice(productType.type).format()
                        }}</span>
                        <span v-else>{{
                          getProductTypePriceVariation(
                            productType.type,
                          ).format()
                        }}</span>
                      </span>
                    </li>
                    <li
                      class="booking-widget__basket-item"
                      v-if="partySize > 0 && selectedDay"
                    >
                      <span class="booking-widget__basket-item-title"
                        >Total</span
                      >
                      <span class="booking-widget__basket-price">
                        {{ totalPrice.format() }}
                      </span>
                    </li>
                  </ul>
                </div>
              </div>

              <div class="booking-widget__footer">
                <button
                  type="button"
                  id="add-to-basket-btn"
                  class="btn btn--alt booking-widget__add-to-basket-btn"
                  :class="{ disabled: !addToBasketEnabled }"
                  :disabled="!addToBasketEnabled"
                  @click="addToBasket"
                >
                  {{ addToBasketText }}
                </button>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Spinner from './Spinner.vue';
import Calendar from './Calendar.vue';
import { ref, computed, onMounted, onUpdated, watch } from 'vue';
import { useStore } from 'vuex';
import { setCurrencyFormat } from '../../utils';

export default {
  name: 'BookingWidget',
  components: {
    spinner: Spinner,
    calendar: Calendar,
  },
  props: {
    store: {
      type: String,
      default: 'basket',
    },
    prioticketId: Number,
    productId: Number,
    subUmbracoId: Number,
    selectPartyText: String,
    selectDateText: String,
    selectTimeText: String,
    addToBasketText: String,
    errorTitleText: String,
    errorDescriptionText: String,
    errorButtonText: String,
    currencyFormatInfo: null,
    editMode: {
      type: Boolean,
      default: false,
    },
    productTitle: String,
  },

  setup(props) {
    const showError = ref(false);
    const productData = ref(null);
    const availablityData = ref(null);
    const party = ref([]);
    const selectedTimeslotId = ref(null);
    const selectedDay = ref(null);
    const lastValidSelectedDay = ref(null);
    const currentMonth = ref(null);
    const currentCurrency = ref(null);
    const activeProductId = ref(null);
    const activePrioticketId = ref(null);
    const activeSubUmbracoId = ref(null);
    const availability_enabled = ref(true);
    const combiProducts = ref(false);
    const minBookingQuantity = ref(null);
    const maxBookingQuantity = ref(null);
    const QuantityPricing = ref(false);
    const PriceType = ref(null);

    const store = useStore();

    const isBusy = computed(() => store.state[props.store].isBusy);
    const productAdded = computed(() => store.state[props.store].productAdded);
    const basketErrorStatus = computed(
      () => store.state[props.store].errorStatus,
    );
    const productToEdit = computed(
      () => store.state[props.store].productToEdit,
    );

    const modalId = computed(() => {
      let id = 'booking-modal';
      if (!props.editMode) {
        id += `-${activeSubUmbracoId.value}`;
      }
      return id;
    });

    const addToBasketEnabled = computed(() => {
      return (
        partyContainsAnAdult.value &&
        withinMinMaxBookingQuantity() &&
        !isBusy.value &&
        ((selectedDay.value != null &&
          selectedTimeslotId.value != null &&
          selectedTimeHasCapacity()) ||
          availability_enabled.value == false)
      );
    });

    const partySize = computed(() => {
      let total = 0;
      for (let i = 0; i < party.value.length; i++) {
        const type = party.value[i].type;
        var pax = getProductTypePax(type);
        if (pax == null) {
          pax = 1;
        }
        total += pax * party.value[i].count;
      }
      return total;
    });

    const partyContainsAnAdult = computed(() => {
      for (let i = 0; i < party.value.length; i++) {
        const type = party.value[i].type;
        if (
          type === 'ADULT' ||
          type === 'SENIOR' ||
          type === 'PERSON' ||
          type === 'MILITARY' ||
          type === 'STUDENT' ||
          type === 'IMPAIRED' ||
          type === 'ITEM' ||
          type === 'FAMILY' ||
          type === 'GROUP' ||
          type === 'CUSTOM'
        ) {
          return true;
        }
      }
      return false;
    });

    const totalPrice = computed(() => {
      var total = currentCurrency.value(0);
      if (QuantityPricing.value) {
        for (let i = 0; i < party.value.length; i++) {
          let variationAmount = getVariationAmount(party.value[i].type);
          let listPrice = getProductTypePrice(party.value[i].type);
          let variationCalc;
          if (PriceType.value === 'INDIVIDUAL') {
            variationCalc = partySize.value * variationAmount[0].Amount;
          } else {
            variationCalc = variationAmount[0].Amount;
          }
          let calc = listPrice.value + variationCalc;
          total = total.add(calc);
        }
      } else {
        for (let i = 0; i < party.value.length; i++) {
          const totalForType = getProductTypePrice(
            party.value[i].type,
          ).multiply(party.value[i].count);
          total = total.add(totalForType);
        }
      }
      return total;
    });

    const slotsWithCapacity = () => {
      var slots = [];
      if (availablityData.value) {
        slots = filterSlotsForParty(availablityData.value);
      }
      return slots;
    };
    const slotsForSelectedDay = () => {
      let slots = [];
      if (selectedDay.value && availablityData.value) {
        slots = availablityData.value.filter(item =>
          selectedDay.value.isSame(item.availability_from_date_time, 'day'),
        );
      }
      return slots;
    };
    const slotsForSelectedDayWithCapacity = () => {
      return filterSlotsForParty(slotsForSelectedDay());
    };
    const selectedTimeHasCapacity = () => {
      const slotForSelectedTime = slotsForSelectedDayWithCapacity().find(
        item => item.availability_id === selectedTimeslotId.value,
      );
      return slotForSelectedTime != null;
    };
    const calendarAvailability = () => {
      let availability = [];
      for (let i = 0; i < slotsWithCapacity().length; i++) {
        const day = {
          date: slotsWithCapacity()[i].availability_from_date_time,
        };
        availability.push(day);
      }
      return availability;
    };
    const currentMonthContainsSelectedDay = () => {
      return (
        selectedDay.value != null &&
        currentMonth.value != null &&
        selectedDay.value.isSame(currentMonth.value, 'month')
      );
    };
    const productTypeDetails = () => {
      var productTypeDetails;
      if (productData.value) {
        productTypeDetails = [];
        if (lastValidSelectedDay.value) {
          const dataForDate = productData.value.find(item =>
            lastValidSelectedDay.value.isBetween(
              item.StartDate,
              item.EndDate,
              null,
              '[]',
            ),
          );
          if (dataForDate) {
            productTypeDetails = dataForDate.ProductTypeDetails;
          } else {
            selectFirstValidDay();
          }
        }
      }
      return productTypeDetails;
    };
    const withinMinMaxBookingQuantity = () => {
      if (
        minBookingQuantity.value &&
        partySize.value < minBookingQuantity.value
      ) {
        return false;
      }
      if (
        maxBookingQuantity.value &&
        partySize.value > maxBookingQuantity.value
      ) {
        return false;
      }
      return true;
    };

    const selectFirstValidDay = () => {
      let startDate = null;
      const today = moment();
      productData.value.forEach(function (season) {
        let seasonStartDate = moment(season.StartDate);
        if (seasonStartDate.isAfter(today)) {
          if (startDate == null || seasonStartDate.isBefore(startDate)) {
            startDate = seasonStartDate;
          }
        }
      });
      selectedDay.value = startDate;
      lastValidSelectedDay.value = selectedDay.value.clone();
      currentMonth.value = selectedDay.value.clone();
      updateAvailabiltyDataForMonth(currentMonth.value);
    };
    const updateQty = (type, qty) => {
      updateBasket(type, parseInt(qty?.target?.value || qty));
    };

    const toggleDay = day => {
      if (selectedDay.value && day.format() == selectedDay.value.format()) {
        selectedDay.value = moment().add(1, 'd');
      } else {
        selectedDay.value = day;
      }
      selectedTimeslotId.value = null;
    };

    const updateBasket = (type, qty) => {
      if (qty <= 0) {
        party.value = party.value.filter(item => item.type !== type);
      } else {
        const productType = getBasketItemByType(type);
        if (productType) {
          console.log(
            'we already have that type in the party update the qty for it',
          );
          productType.count = qty;
        } else {
          console.log(
            'we dont have that type in the party - create a fresh object for it',
          );
          const productDetails = {
            type: type,
            count: qty,
          };
          party.value = party.value.concat(productDetails);
        }
      }
    };

    // this searches for a user that matches customer type (child, adult etc)
    // the user has details associated to them such as Id and price
    // this is what we return
    const getProductTypeObject = type => {
      // because the dates in the productData API are in a different format we need to adjust our selected date so we can make a match
      const selectedDate = moment(selectedDay.value).format('YYYY-MM-DD');
      let date;
      let productDetails;

      for (let i = 0; i < productData.value.length; i++) {
        date = moment(productData.value[i].StartDate).format('YYYY-MM-DD');

        if (moment(selectedDate).isSameOrAfter(date)) {
          productDetails = productData.value[i].ProductTypeDetails;
        }
      }

      return productDetails.find(item => item.Type === type);
    };

    const getProductTypeLabel = type => {
      const productTypeObject = getProductTypeObject(type);
      let label = productTypeObject.Label;
      if (productTypeObject.AgeFrom && productTypeObject.AgeTo) {
        label +=
          ' (' +
          productTypeObject.AgeFrom +
          '-' +
          productTypeObject.AgeTo +
          ')';
      } else if (productTypeObject.AgeFrom) {
        label += ' (' + productTypeObject.AgeFrom + '+)';
      }
      return label;
    };

    const getProductTypeInitialValue = type => {
      var initialValue;
      if (props.editMode) {
        var item = productToEdit.value.PriceBreakDown.find(
          item => item.TicketType === type,
        );
        if (item) {
          initialValue = item.Count;
        } else {
          initialValue = 0;
        }
      } else {
        const productTypeObject = getProductTypeObject(type);
        initialValue = productTypeObject.DefaultAmountSelected;
      }
      return parseInt(initialValue);
    };
    const getProductTypePrice = type => {
      let price;
      if (selectedDay.value) {
        const productTypeObject = getProductTypeObject(type);
        price = currentCurrency.value(productTypeObject.ListPrice);
      }
      return price;
    };
    const getProductTypePriceVariation = type => {
      const variationPrice = totalPrice.value / party.value[0].count;
      const price = currentCurrency.value(variationPrice);
      return price;
    };
    const getVariationAmount = type => {
      let price;
      if (selectedDay.value) {
        const productTypeObject = getProductTypeObject(type);
        const variations = productTypeObject.Variations;
        let numberOfGuests = partySize.value;
        if (numberOfGuests === 1) {
          numberOfGuests = 2;
        }
        price = variations.filter(obj => {
          return obj.Max === numberOfGuests;
        });
      }
      return price;
    };

    const getProductTypePax = type => {
      let pax;
      if (selectedDay.value) {
        const productTypeObject = getProductTypeObject(type);
        pax = productTypeObject.PAX;
      }
      return pax;
    };
    const getBasketItemByType = type => {
      return party.value.find(item => item.type === type);
    };

    const formatTime = time => {
      return moment.utc(time).format('HH:mm');
    };

    const slotHasCapacity = slot => {
      return partySize.value <= slot.availability_spots.availability_spots_open;
    };

    const filterSlotsForParty = slotsArray => {
      return slotsArray.filter(
        item =>
          partySize.value <= item.availability_spots.availability_spots_open,
      );
    };

    const adjustAvailabilityForQtyInBasket = () => {
      const availabilityItem = availablityData.value.find(
        item => item.availability_id == productToEdit.value.AvailabilityId,
      );
      if (availabilityItem) {
        let partySizeInBasket = 0;
        for (let i = 0; i < productToEdit.value.PriceBreakDown.length; i++) {
          const item = productToEdit.value.PriceBreakDown[i];
          let pax = getProductTypePax(item.TicketType);
          if (pax == null) {
            pax = 1;
          }
          partySizeInBasket += pax * item.Count;
        }
        const newTotal =
          parseInt(
            availabilityItem.availability_spots.availability_spots_open,
          ) + partySizeInBasket;
        availabilityItem.availability_spots.availability_spots_open =
          newTotal.toString();
      }
    };
    const updateAvailabiltyDataForMonth = month => {
      currentMonth.value = month;
      availablityData.value = null;
      const pars = {
        umbracoProductId: activeProductId.value,
        subUmbracoProductId: activeSubUmbracoId.value,
        fromDate: month.startOf('month').format(),
        toDate: month.endOf('month').format(),
      };
      fetch(
        '/umbraco/Api/ProductsApi/GetProductAvailability?' +
          new URLSearchParams(pars),
      )
        .then(response => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error('Network response was not ok');
          }
        })
        .then(data => {
          if (month.valueOf() == currentMonth.value.valueOf()) {
            availablityData.value = data.availability_items;
            availability_enabled.value = data.availability_enabled;
            if (props.editMode) {
              adjustAvailabilityForQtyInBasket();
            }
          }
        })
        .catch(error => {
          console.log(error);
          showError.value = true;
        });
    };
    const getProductData = () => {
      productData.value = null;
      const pars = { productId: activeSubUmbracoId.value };

      fetch(
        '/umbraco/Api/ProductApi/ProductDetails?' + new URLSearchParams(pars),
      )
        .then(response => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error('Network response was not ok');
          }
        })
        .then(data => {
          productData.value = data.Seasons;
          combiProducts.value = data.CombiProducts;
          minBookingQuantity.value = data.MinBookingQuantity;
          maxBookingQuantity.value = data.MaxBookingQuantity;
          QuantityPricing.value = data.QuantityPricing;
          PriceType.value = data.PriceType;
        })
        .catch(error => {
          console.log(error);
          showError.value = true;
        });
    };
    const setupScrolling = () => {
      updateScroll();
      $(document).ready(updateScroll);
      $(window).resize(updateScroll);
      $('#' + modalId.value).scroll(updateScroll);
    };
    const updateScroll = () => {
      const currentBottom = $(document).scrollTop() + $(window).height();
      let footerPosition =
        $('.booking-widget').offset().top + $('.booking-widget').outerHeight();
      if ($('.booking-widget__footer').hasClass('stuck')) {
        const priceBarHeight = $('.booking-widget__footer').outerHeight();
        footerPosition = footerPosition + priceBarHeight; //+ 25;
      }
      if (currentBottom > footerPosition) {
        $('.booking-widget__footer').removeClass('stuck');
      } else {
        $('.booking-widget__footer').addClass('stuck');
      }
    };

    const createBookingData = () => {
      let data = new Object();
      data.booking_external_reference = activeSubUmbracoId.value;
      data.product_id = activePrioticketId.value;
      data.product_availability_id = selectedTimeslotId.value;
      data.product_type_details = [];

      party.value.map(function (productType) {
        data.product_type_details.push({
          product_type_id: getProductTypeObject(productType.type).Id,
          product_type_count: productType.count,
          product_type_pax: getProductTypePax(productType.type),
          product_type_options: [],
        });
      });

      data.product_options = [];
      data.product_combi_details = [];
      if (combiProducts.value && combiProducts.value.length > 0) {
        data.product_combi_details = combiProducts.value
          .filter(product => product.AvailabilityRequired === false)
          .map(product => ({ product_id: product.PrioticketId }));
      }
      console.log('booking data' + JSON.stringify(data, null, 2));
      return data;
    };

    const addToBasket = () => {
      let bookingData = createBookingData();
      if (props.editMode) {
        bookingData.booking_reservation_reference =
          productToEdit.value.ReservationReference;
        console.log('ADD TO BASKET (edit)' + JSON.stringify(bookingData));
        store.dispatch(`${props.store}/editProduct`, bookingData);
      } else {
        console.log('ADD TO BASKET ' + JSON.stringify(bookingData));
        store.dispatch(`${props.store}/addToBasket`, bookingData);
      }
    };

    const reset = () => {
      store.dispatch(`${props.store}/clearError`);
      showError.value = false;
      productData.value = null;
      availablityData.value = null;
      party.value = [];
      selectedTimeslotId.value = null;
      selectedDay.value = null;
      lastValidSelectedDay.value = null;
      currentMonth.value = null;
      activeProductId.value = null;
      activePrioticketId.value = null;
      activeSubUmbracoId.value = null;
    };

    onMounted(() => {
      reset();
      currentCurrency.value = value =>
        setCurrencyFormat(value, props.currencyFormatInfo);

      if (props.editMode === false) {
        activeProductId.value = props.productId;
        activePrioticketId.value = props.prioticketId;
        activeSubUmbracoId.value = props.subUmbracoId;
        selectedDay.value = moment().add(1, 'd'); // default select day to tomorrow
        lastValidSelectedDay.value = selectedDay.value.clone();
        currentMonth.value = selectedDay.value.clone();
        getProductData();
        updateAvailabiltyDataForMonth(currentMonth.value);
      }

      $('#' + modalId.value).on('hidden.bs.modal', function () {
        showError.value = false;
        store.dispatch(`${store.value}/clearError`);
        store.dispatch(`${store.value}/setProductToEdit`, null);
      });

      setupScrolling();
    });

    onUpdated(() => {
      updateScroll();
    });

    watch(selectedDay, newSelectedDay => {
      if (newSelectedDay != null) {
        lastValidSelectedDay.value = newSelectedDay.clone();
      }
    });

    watch(productToEdit, newProductToEdit => {
      console.log('productToEdit watch');
      if (props.editMode) {
        reset();
        if (newProductToEdit) {
          activeSubUmbracoId.value = newProductToEdit.SubUmbracoProductId;
          activeProductId.value = newProductToEdit.UmbracoProductId;
          activePrioticketId.value = newProductToEdit.PrioticketProductId;
          selectedDay.value = moment(newProductToEdit.TravelDateTimeOffset);
          lastValidSelectedDay.value = selectedDay.value.clone();
          currentMonth.value = selectedDay.value.clone();
          selectedTimeslotId.value = newProductToEdit.AvailabilityId;
          getProductData();
          updateAvailabiltyDataForMonth(currentMonth.value);
          $('#' + modalId.value).modal('show');
        } else {
          $('#' + modalId.value).modal('hide');
        }
      }
    });

    watch(productAdded, newProductAdded => {
      if (newProductAdded != null) {
        $('#' + modalId.value).modal('hide');
      }
    });

    watch(basketErrorStatus, status => {
      if (status) {
        showError.value = true;
      }
    });

    return {
      showError,
      productData,
      availablityData,
      party,
      selectedTimeslotId,
      selectedDay,
      lastValidSelectedDay,
      currentMonth,
      currentCurrency,
      activeProductId,
      activePrioticketId,
      activeSubUmbracoId,
      availability_enabled,
      combiProducts,
      minBookingQuantity,
      maxBookingQuantity,
      QuantityPricing,
      PriceType,

      modalId,
      partySize,
      totalPrice,
      calendarAvailability,
      currentMonthContainsSelectedDay,
      productTypeDetails,
      slotsForSelectedDay,
      updateAvailabiltyDataForMonth,

      addToBasketEnabled,
      selectFirstValidDay,
      updateQty,
      toggleDay,
      getProductTypeLabel,
      getProductTypePrice,
      getProductTypeInitialValue,
      getProductTypePriceVariation,
      formatTime,
      slotHasCapacity,
      filterSlotsForParty,
      addToBasket,
    };
  },
};
</script>
