<template>
  <div
    class="modal remove-modal"
    data-backdrop="true"
    id="remove-modal"
    tabindex="-1"
    role="dialog"
    :aria-label="ariaLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <button
          type="button"
          class="modal-close"
          data-dismiss="modal"
          aria-label="closeText"
        ></button>

        <div class="modal-body">
          <transition name="fade" mode="out-in">
            <div v-if="errorStatus == true" key="1">
              <h2 class="remove-modal__error-title">{{ errorTitleText }}</h2>
              <p class="remove-modal__error-body">{{ errorDescriptionText }}</p>
              <div class="remove-modal__error-button-wrap">
                <button
                  class="btn btn--alt remove-modal__error-button"
                  data-dismiss="modal"
                >
                  {{ errorButtonText }}
                </button>
              </div>
            </div>

            <div v-else key="2">
              <h4>{{ titleText }}</h4>
              <p class="remove-modal__intro">{{ introText }}</p>

              <div class="remove-modal__item" v-if="productToRemove">
                <div class="remove-modal__item-img-holder">
                  <img
                    :data-src="productToRemove.ImageUrl"
                    :alt="productToRemove.Title"
                    class="remove-modal__item-img lazyload"
                    data-object-fit="cover"
                  />
                </div>

                <div class="remove-modal__item-content">
                  <p class="remove-modal__title">{{ productToRemove.Title }}</p>
                  <div
                    class="star-rating"
                    role="img"
                    :title="productToRemove.FeefoStarRating.Summary"
                    :aria-label="productToRemove.FeefoStarRating.Summary"
                    v-if="productToRemove.FeefoStarRating.FeefoStars"
                  >
                    <span
                      v-for="(i, index) in 5"
                      class="star-rating__star"
                      :class="[
                        productToRemove.FeefoStarRating.FeefoStars[index],
                      ]"
                      :key="index"
                      aria-hidden="true"
                    ></span>
                  </div>
                </div>

                <div class="remove-modal__lines">
                  <div class="basket-line" v-if="productToRemove.TravelDate">
                    <div class="basket-line__img">
                      <svg
                        class="basket-line__svg"
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="20"
                        viewBox="0 0 18 20"
                      >
                        <use
                          xlink:href="/Static/images/sprites/symbols.svg#date"
                        />
                      </svg>
                    </div>
                    <p class="basket-line__text">
                      {{ productToRemove.TravelDate }}
                    </p>
                  </div>
                  <template v-if="productToRemove.PriceBreakDown">
                    <div
                      class="basket-line"
                      v-for="(item, i) in this.productToRemove.PriceBreakDown"
                      :key="i"
                    >
                      <div class="basket-line__img">
                        <svg
                          class="basket-line__svg"
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                        >
                          <use
                            xlink:href="/Static/images/sprites/symbols.svg#person"
                          />
                        </svg>
                      </div>
                      <p class="basket-line__text">
                        {{ item.TicketTypeLabel }}
                      </p>
                      <p class="basket-line__cost">
                        {{ item.Count }} x
                        {{ formatCurrency(item.IndividualPrice).format() }}
                      </p>
                    </div>
                  </template>
                </div>
              </div>

              <div class="remove-modal__buttons">
                <button
                  class="btn btn--thin btn--alert-icon remove-modal__remove-btn"
                  @click="removeItem"
                  :class="{ disabled: isBusy }"
                >
                  {{ yesButtonText }}
                </button>
                <button
                  class="btn btn--thin btn--alt4 remove-modal__cancel-btn"
                  data-dismiss="modal"
                >
                  {{ noButtonText }}
                </button>
              </div>
            </div>
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, watch, onMounted, onUpdated } from 'vue';
import { useStore } from 'vuex';
import { setCurrencyFormat, formatPriceCurrency } from '../../utils';

export default {
  name: 'BasketRemove',
  props: {
    store: {
      type: String,
      default: 'basket',
    },
    ariaLabel: String,
    closeText: String,
    titleText: String,
    introText: String,
    emptyText: String,
    noButtonText: String,
    yesButtonText: String,
    errorTitleText: String,
    errorDescriptionText: String,
    errorButtonText: String,
    currencyFormatInfo: null,
  },
  setup(props) {
    const store = useStore();
    const currentCurrency = ref(null);

    const isBusy = computed(() => store.state[props.store].isBusy);
    const productToRemove = computed(
      () => store.state[props.store].productToRemove,
    );
    const errorStatus = computed(() => store.state[props.store].errorStatus);
    const basketReservationId = computed(
      () => store.state[props.store].basketReservationId,
    );

    const formatCurrency = formatPriceCurrency(currentCurrency);

    const removeItem = () => {
      const params = {
        UmbracoId: productToRemove.value.UmbracoProductId,
        ReservationId: basketReservationId.value,
        BookingReservationId: productToRemove.value.ReservationReference,
      };

      store.dispatch(`${props.store}/removeProduct`, params);
    };

    watch(productToRemove, () => {
      if (productToRemove.value) {
        $('#remove-modal').modal('show');
      } else {
        $('#remove-modal').modal('hide');
      }
    });

    onMounted(() => {
      currentCurrency.value = value =>
        setCurrencyFormat(value, props.currencyFormatInfo);

      $('#remove-modal').on('hidden.bs.modal', () => {
        store.dispatch(`${props.store}/setProductToRemove`, null);
        store.dispatch(`${props.store}/clearError`);
      });
    });

    onUpdated(() => {
      objectFitPolyfill();
    });

    return {
      isBusy,
      productToRemove,
      errorStatus,
      basketReservationId,
      formatCurrency,
      removeItem,
    };
  },
};
</script>
