// categories and filters are essentially the same thing
// categories are a simplified version of filters (an array vs object with values and selected etc...)

export default {
  setCulture(state, culture) {
    console.log('setCulture :' + culture + ': ');
    state.Culture = culture;
  },

  // set items
  setItems(state, items) {
    state.items = items;
  },

  // set items remaining
  setItemsRemaining(state, itemsRemaining) {
    state.itemsRemaining = itemsRemaining;
  },

  // set item count
  setItemsCount(state, itemsCount) {
    state.itemsCount = itemsCount;
  },

  // set all the filters
  setFilters(state, filters) {
    state.filters = filters;
  },

  setFilterSelected(state, data) {
    state.filters[data.filterKey].Children[data.itemKey].Selected =
      data.checked;
  },

  // toggle all the filters
  setAllFiltersSelected(state, selected) {
    state.filters.forEach(filter => {
      filter.Children.forEach(item => {
        item.Selected = selected;
      });
    });
  },

  // set sort order options
  setSortOrderOptions(state, sortOrderOptions) {
    state.SortOrderOptions = sortOrderOptions;
  },

  // set the sort order
  setSortOrder(state, sortOrder) {
    state.SortOrder = sortOrder;
  },

  // set the search term
  setSearchTerm(state, searchTerm) {
    state.SearchTerm = searchTerm;
  },

  // set the current search term
  setCurrentSearch(state, currentSearch) {
    state.CurrentSearch = currentSearch;
  },

  // the amount of results we need to take from the API
  setTake(state, take) {
    take = parseInt(take) || 0;
    if (take < state.initialTake) {
      take = state.initialTake;
    }
    state.Take = take;
  },

  // the amount of results we need to take from the API (when resetting)
  setInitialTake(state, initialTake) {
    state.initialTake = parseInt(initialTake);
  },

  // which result we load from
  setFrom(state, from) {
    state.From = parseInt(from) || 0;
  },

  // the parent page id from umbraco (used in api calls if set)
  setParentId(state, parentId) {
    state.parentId = parentId;
  },

  // set the tag
  setTag(state, tag) {
    state.Tag = tag;
  },

  // set categories
  setCategories(state, categories) {
    if (typeof categories === 'string') {
      categories = [categories];
    }
    state.Categories = categories.filter(Boolean);
  },

  /* setChildItemsOnly(state, childItems) {
    state.childItemsOnly = childItems;
  },*/

  setIsSiteSearch(state, isSiteSearch) {
    state.isSiteSearch = isSiteSearch;
  },

  // add a category
  addCategory(state, category) {
    if (state.Categories.indexOf(category) === -1) {
      state.Categories.push(category.toString());
    }
  },

  // remove a category
  removeCategory(state, category) {
    const index = state.Categories.indexOf(category.toString());

    if (index !== -1) {
      state.Categories.splice(index, 1);
    }
  },

  setPriceRangeBarHeights(state, priceRangeBarHeights) {
    state.PriceRangeBarHeights = priceRangeBarHeights;
  },

  setPriceRangeMinPrice(state, priceRangeMinPrice) {
    state.PriceRangeMinPrice = priceRangeMinPrice;
  },

  setPriceRangeMaxPrice(state, priceRangeMaxPrice) {
    state.PriceRangeMaxPrice = priceRangeMaxPrice;
  },

  setMinPrice(state, minPrice) {
    state.MinPrice = minPrice;
  },

  setMaxPrice(state, maxPrice) {
    state.MaxPrice = maxPrice;
  },

  setDisplayText(state, text) {
    state.DisplayText = text;
  },

  setCurrencyFormatInfo(state, currencyFormatInfo) {
    state.currencyFormatInfo = currencyFormatInfo;
  },
};
