const init = () => {

  // Find a target to scroll to if the user is interacting with forms. This happens in different ways:
  // - Find a submit message that is not wrapped in a form (the message is always present even when not visible
  // as the form has just been loaded.
  // - If the message wasn't found then try to target a form containing a hidden field that indicates that the form's
  // step/page is not the first(0) one.
  // - If that wasn't found either then try to find a form that has PreviousClicked = true where the user just
  // navigated back. This is CURRENTLY NOT WORKING possibly because of an Umbraco Forms issue for which I've raise
  // a github issue here https://github.com/umbraco/Umbraco-CMS/issues/4443.
  let target = $('.umbraco-forms-submitmessage:not(form .umbraco-forms-submitmessage)')[0]
      || $('input[type="hidden"][name="FormStep"]:not([value="0"]),'
          + 'input[type="hidden"][name="PreviousClicked"][value="true"]').parent('form')[0];

  let thankyouMessage = $('.umbraco-forms-submitmessage');

  // If we have found a target to scroll to then smooth scroll there.
  if (target) {

    setTimeout(function () { scrollToTarget(thankyouMessage); }, 400);

    // If inside an accordion then click it to expand it and wait the default 400ms to find the form.
    // let parentsAccordion = $(target).parents('.accordion-panel');

    // if(parentsAccordion.length > 0) {
    //   parentsAccordion.find('.js-get-in-touch-trigger button').click();
    //   setTimeout(function () { scrollToTarget(parentsAccordion); }, 400);
    // } else {
    //   scrollToTarget(target);
    // }

  }

};

const scrollToTarget = (target) => {
  // Scroll page to the target. Here 20 is just to add some margin, but you could need to add
  // the height of your sticky navbar too.
  let targetOffset = $(target).offset().top - 200;
  $('html,body').animate({scrollTop: targetOffset});
};

export { init }