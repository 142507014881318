

const init = () => {

  
  let updatePosition = function () {

    var currentBottom = $(document).scrollTop() + $(window).height(); 
    let footerPosition = $('.footer-primary').offset().top;    

    if($('.price-bar').hasClass('price-bar--stuck')) {
      let priceBarHeight = $('.price-bar').outerHeight();
      footerPosition = footerPosition + priceBarHeight;
    }   

    // console.log("currentBottom " + currentBottom );
    // console.log("footerPosition " + footerPosition );

    if(currentBottom > footerPosition) {
      $('.price-bar').removeClass('price-bar--stuck');
    } else {
      $('.price-bar').addClass('price-bar--stuck');   
    }

  }
  
  if ($('.price-bar').length) {
    $(document).ready(updatePosition);
    $(window).resize(updatePosition);
    $(window).scroll(updatePosition);
  }

};



export {
  init
}