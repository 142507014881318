<template>
  <div class="applied-filters">
    <span class="applied-filters__title" v-if="categories.length > 0">{{
      label
    }}</span>
    <template v-for="(filter, filterKey) in filters">
      <template v-for="(item, itemKey) in filter.Children">
        <button
          v-if="item.Selected"
          class="applied-filters__btn"
          @click="removeFilter(filterKey, itemKey, $event)"
          :disabled="!isMobile"
        >
          {{ item.Label }}
        </button>
      </template>
    </template>
    <span class="applied-filters__results-count" v-if="categories.length > 0"
      >({{ itemsCount }})</span
    >
  </div>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { isMobile } from '../mixins/isMobile.js';

export default {
  name: 'AppliedFilters',
  mixins: [isMobile],
  props: {
    store: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: 'Results for',
    },
  },
  setup(props) {
    const store = useStore();
    const filters = computed(() => store.state[props.store].filters);
    const categories = computed(() => store.state[props.store].Categories);
    const itemsCount = computed(() => store.state[props.store].itemsCount);

    const removeFilter = (filterKey, itemKey) => {
      store.dispatch(`${props.store}/toggleFilterValue`, {
        filterKey: filterKey,
        itemKey: itemKey,
        checked: false,
      });
      store.dispatch(`${props.store}/fetchItems`);
    };

    return {
      filters,
      categories,
      itemsCount,
      removeFilter,
    };
  },
};
</script>
