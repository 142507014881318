const init = () => {

  // this adds the touch start listeners to all the interactable elements in the page
  window.onload = function() {
    if(/iP(hone|ad)/.test(window.navigator.userAgent)) {
      var elements = document.querySelectorAll('button');
      var emptyFunction = function() {
        console.log('');
      };
      for(var i = 0; i < elements.length; i++) {
        elements[i].addEventListener('touchstart', emptyFunction, false);
      }
    }
  };

};

export { init }