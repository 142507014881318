const init = () => {

  $(document).on('change', '#currency-picker-select', function() {
    const action = $(this.form).attr('action');
    const actionWithoutQuery = action.split('?')[0];
    const queryString = location.search;
    $(this.form).attr('action', actionWithoutQuery + queryString);
    $(this.form).submit();
  });

  // desktop form
  /*
  $(document).on('change','.currency-picker__form .currency-picker-select',function() {
    const action = $('.secondary-nav .currency-picker__form').attr('action');
    const actionWithoutQuery = action.split('?')[0];
    const queryString = location.search;
    $('.secondary-nav .currency-picker__form').attr('action', actionWithoutQuery + queryString);
    $('.secondary-nav .currency-picker__form').submit();
  });

  // mobile form
  $(document).on('change','.secondary-nav__list--mobile .currency-picker__form .currency-picker-select',function() {
    const action = $('.secondary-nav__list--mobile .currency-picker__form').attr('action');
    const actionWithoutQuery = action.split('?')[0];
    const queryString = location.search;
    $('.secondary-nav__list--mobile .currency-picker__form').attr('action', actionWithoutQuery + queryString);
    $('.secondary-nav__list--mobile .currency-picker__form').submit();
  });*/

}

export {
  init
}
