import { createApp } from 'vue';
import { createStore } from 'vuex';
import FilterIndexStore from './stores/filter-index/index';
import ContentIndexStore from './stores/content-index/index';
import SearchStore from './stores/search/index';
import BasketStore from './stores/basket/index';

const store = createStore({
  modules: {
    'filter-index': FilterIndexStore,
    'content-index': ContentIndexStore,
    search: SearchStore,
    basket: BasketStore,
  },
});

const app = createApp();
app.use(store);

export default store;
