const init = () => {

  $('.carousel__container').slick({
    dots: true,
    adaptiveHeight: true,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: false,
    pauseOnDotsHover: false,
    pauseOnFocus: false,
    swipeToSlide: true
  });

  _setupPause();

  $('.carousel-header__container').slick({
    dots: true,
    autoplay: false,
    autoplaySpeed: 5000,
    swipeToSlide: true,
  });
};

const _setupPause = () => {
  let htmlControls = '<button class="carousel-play-btn js-carousel-button" role="button" aria-label="Pause carousel">Pause</button>';

  $('ul.slick-dots').wrap('<div class="slick-dots-wrapper" id="js-slick-dots-wrapper"></div>');

  // Insert the play button after the last list item
  $('ul.slick-dots').after(htmlControls);

  $('.js-carousel-button').on('click', (e) => { _onClickPause(e); });
}

const _onClickPause = (e) => {
  let $target = $(e.target);

  if ( $target.hasClass('is-paused') ) {
    $target.removeClass('is-paused');
    $target.text('Pause');
    $('.carousel__container').slick('slickPlay');
  } else {
    $target.addClass('is-paused');
    $target.text('Play');
    $('.carousel__container').slick('slickPause');
  }

};

export { init }