<template>
  <div class="inspire-me">
    <div class="container-fluid">
      <div class="row">
        <div class="col">
          <h1 class="inspire-me__title">{{ title }}</h1>
          {{ page }}
        </div>
      </div>
    </div>

    <div class="inspire-me__main">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-10 offset-md-1">
            <transition name="fade" key="page1" v-if="currentPage == 1">
              <div class="inspire-me__filter-wrapper" v-if="currentPage == 1">
                <div
                  class="inspire-me__filter"
                  v-for="(filter, index) in page1Filters"
                  :key="index"
                >
                  <h2 class="inspire-me__filter-title">{{ filter.Title }}</h2>
                  <p class="inspire-me__filter-text">
                    {{ filter.Description }}
                  </p>
                  <p class="inspire-me__filter-tip">{{ multiSelectTip }}</p>
                  <form class="inspire-me__form" v-on:submit.prevent>
                    <fieldset class="inspire-me__fieldset">
                      <legend class="visually-hidden">
                        {{ filter.AccessabilityLabel }}
                      </legend>
                      <button
                        class="btn btn--thin btn--centre inspire-me__select-all-btn"
                        @click="selectAll(filter.Categories)"
                      >
                        {{ multiSelectButton }}
                      </button>
                      <div class="inspire-me__checkbox-group">
                        <div
                          class="inspire-me__checkbox inspire-me__checkbox--image"
                          v-for="item in filter.Categories"
                          :key="item.CategoryId"
                        >
                          <label class="inspire-me__checkbox-label">
                            <input
                              type="checkbox"
                              ref="checkbox"
                              class="inspire-me__checkbox-input"
                              :value="item.CategoryId"
                              v-model="selectedFilters"
                            />
                            <span class="inspire-me__checkbox-label-content">
                              <span class="inspire-me__checkbox-label-text">{{
                                item.Label
                              }}</span>
                              <span
                                class="inspire-me__checkbox-label-image"
                                v-if="item.Image"
                              >
                                <img
                                  class="img-responsive lazyload"
                                  :data-src="item.Image"
                                  :alt="item.Label"
                                  draggable="false"
                                />
                                <!-- 270x70 -->
                              </span>
                            </span>
                          </label>
                        </div>
                      </div>
                    </fieldset>
                  </form>
                </div>
              </div>
            </transition>
            <transition name="fade" key="page2" v-if="currentPage == 2">
              <div class="inspire-me__filter-wrapper" ref="page2">
                <div
                  class="inspire-me__filter"
                  v-for="(filter, index) in page2Filters"
                  :key="index"
                >
                  <h2 class="inspire-me__filter-title">{{ filter.Title }}</h2>
                  <p class="inspire-me__filter-text">
                    {{ filter.Description }}
                  </p>
                  <form class="inspire-me__form" id="inspire-me-page-2-form">
                    <fieldset class="inspire-me__fieldset">
                      <legend class="visually-hidden">
                        {{ filter.AccessabilityLabel }}
                      </legend>
                      <div class="inspire-me__checkbox-group">
                        <div
                          class="inspire-me__checkbox"
                          v-for="item in filter.Categories"
                          :key="item.CategoryId"
                        >
                          <label class="inspire-me__checkbox-label">
                            <input
                              type="checkbox"
                              ref="checkbox"
                              class="inspire-me__checkbox-input"
                              :value="item.CategoryId"
                              v-model="selectedFilters"
                            />
                            <span class="inspire-me__checkbox-label-content">
                              <span class="inspire-me__checkbox-label-text">{{
                                item.Label
                              }}</span>
                            </span>
                          </label>
                        </div>
                      </div>
                    </fieldset>
                  </form>
                </div>
              </div>
            </transition>

            <button
              type="button"
              class="btn btn--thin inspire-me__next-btn"
              @click.stop="next"
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';

export default {
  name: 'InspireMe',

  props: {
    page: String,
    title: String,
    multiSelectTip: String,
    multiSelectButton: String,
    group1: Object,
    group2: Object,
    group3: Object,
    preselectedCategories: Array,
    productSearchPageUrl: String,
  },

  setup(props) {
    const currentPage = ref(1);
    const selectedFilters = ref([]);

    const page1Filters = [props.group1];
    const page2Filters = [props.group2, props.group3];

    const selectAll = categories => {
      categories.forEach(item => {
        if (!selectedFilters.value.includes(item.CategoryId)) {
          selectedFilters.value.push(item.CategoryId);
        }
      });
    };

    const next = () => {
      if (currentPage.value === 1) {
        currentPage.value++;
      } else {
        gotoSearchPage();
      }
    };

    const gotoSearchPage = () => {
      let link = props.productSearchPageUrl + '?';
      const encoded = [];

      props.preselectedCategories.forEach(item => {
        encoded.push('Categories=' + item);
      });

      selectedFilters.value.forEach(item => {
        encoded.push('Categories=' + item);
      });

      link += encoded.join('&');
      window.location.href = link;
    };

    return {
      currentPage,
      selectedFilters,
      page1Filters,
      page2Filters,
      selectAll,
      next,
    };
  },
};
</script>
