import { createApp } from 'vue';
import store from './vuex/store';

import BookingWidget from './vue/components/BookingWidget.vue'; // booking widget vue component
import FilterIndex from './vue/components/FilterIndex.vue'; // filter vue component
import FilterIndexItem from './vue/components/FilterIndexItem.vue'; // filter item vue component
import ContentIndex from './vue/components/ContentIndex.vue'; // content filter item vue component
import ContentIndexItem from './vue/components/ContentIndexItem.vue'; // content filter item vue component
import InspireMe from './vue/components/InspireMe.vue'; // content filter item vue component
import Newsletter from './vue/components/Newsletter.vue'; // content filter item vue component
import PredictiveSearch from './vue/components/PredictiveSearch.vue'; // search vue component
import BasketPopup from './vue/components/BasketPopup.vue'; // Basket Popup vue component
import Basket from './vue/components/Basket.vue'; // Basket vue component
import BasketRemove from './vue/components/BasketRemove.vue'; // Basket Remove vue component
import BasketCount from './vue/components/BasketCount.vue'; // Basket Count vue component

import * as accordion from './modules/accordion';
import * as cookieBot from './modules/cookie-bot';
import * as getInTouch from './modules/get-in-touch-form';
import * as carousel from './modules/carousel';
import * as helpers from './modules/helpers';
import * as polyfills from './modules/polyfills';
import * as siteNavigation from './modules/primary-nav';
import * as siteSearch from './modules/site-search';
import * as scrollTop from './modules/scroll-top';
import * as activeState from './modules/active-state';
import * as card from './modules/card';
import * as imageGalleryBlock from './modules/image-gallery-block';
import * as formSubmission from './modules/form-submission';
import * as socialSharing from './modules/social-sharing';
import * as stickySocialSharing from './modules/sticky-social-sharing';
import * as priceBar from './modules/price-bar';
import * as featuredProducts from './modules/featured-products';
import * as generalImageListPromoBlock from './modules/general-image-list-promo-block';
import * as checkoutFormSubmission from './modules/checkout-form-submission';
import * as currencySwitcher from './modules/currency-switcher';
import * as forms from './modules/forms';
import * as analytics from './modules/analytics';

import './modules/stackla';

const parseJSON = data => (data !== undefined ? JSON.parse(data) : data);

$(function () {
  $('predictive-search').each((index, el) => {
    const app = createApp(PredictiveSearch, {
      ...el.dataset,
      alt: parseJSON(el.dataset.alt),
    });
    app.mount(el);
  });

  $('booking-widget').each((index, el) => {
    const app = createApp(BookingWidget, {
      ...el.dataset,
      prioticketId: parseJSON(el.dataset.prioticketId),
      productId: parseJSON(el.dataset.productId),
      subUmbracoId: parseJSON(el.dataset.subUmbracoId),
      currencyFormatInfo: parseJSON(el.dataset.currencyFormatInfo),
    });
    app.use(store).mount(el);
  });

  const elFilter = document.querySelector('filter-index');

  if (elFilter) {
    const app = createApp(FilterIndex, {
      ...elFilter.dataset,
      parentId: parseJSON(elFilter.dataset.parentId),
      startAmount: parseJSON(elFilter.dataset.startAmount),
      loadMoreAmount: parseJSON(elFilter.dataset.loadMoreAmount),
    });
    app
      .component('filter-index-item', FilterIndexItem)
      .use(store)
      .mount(elFilter);
  }

  const elContentIndex = document.querySelector('content-index');

  if (elContentIndex) {
    const app = createApp(ContentIndex, {
      ...elContentIndex.dataset,
      startAmount: parseJSON(elContentIndex.dataset.startAmount),
      loadMoreAmount: parseJSON(elContentIndex.dataset.loadMoreAmount),
    });
    app
      .component('content-index-item', ContentIndexItem)
      .use(store)
      .mount(elContentIndex);
  }

  const elNewsletter = document.querySelector('newsletter');

  if (elNewsletter) {
    const app = createApp(Newsletter, {
      contentString: parseJSON(elNewsletter.dataset.contentString),
    });
    app.use(store).mount(elNewsletter);
  }

  const elInspireMe = document.querySelector('inspire-me');

  if (elInspireMe) {
    const app = createApp(InspireMe, {
      ...elInspireMe.dataset,
      group1: parseJSON(elInspireMe.dataset.group1),
      group2: parseJSON(elInspireMe.dataset.group2),
      group3: parseJSON(elInspireMe.dataset.group3),
      preselectedCategories: parseJSON(
        elInspireMe.dataset.preselectedCategories,
      ),
    });
    app.use(store).mount(elInspireMe);
  }

  const elBasketPopup = document.querySelector('basket-popup');

  if (elBasketPopup) {
    const app = createApp(BasketPopup, {
      ...elBasketPopup.dataset,
      currencyFormatInfo: parseJSON(elBasketPopup.dataset.currencyFormatInfo),
    });
    app.use(store).mount(elBasketPopup);
  }

  const elBasketCount = document.querySelector('basket-count');

  if (elBasketCount) {
    const app = createApp(BasketCount);
    app.use(store).mount(elBasketCount);
  }

  const elBasket = document.querySelector('basket');

  if (elBasket) {
    const app = createApp(Basket, {
      ...elBasket.dataset,
      currencyFormatInfo: parseJSON(elBasket.dataset.currencyFormatInfo),
      secureCheckoutNofollow: parseJSON(
        elBasket.dataset.secureCheckoutNofollow,
      ),
      continueShoppingNofollow: parseJSON(
        elBasket.dataset.continueShoppingNofollow,
      ),
    });
    app.use(store).mount(elBasket);
  }

  const elBasketRemove = document.querySelector('basket-remove');

  if (elBasketRemove) {
    const app = createApp(BasketRemove, {
      ...elBasketRemove.dataset,
      currencyFormatInfo: parseJSON(elBasketRemove.dataset.currencyFormatInfo),
    });
    app.use(store).mount(elBasketRemove);
  }

  accordion.init();
  cookieBot.init();
  getInTouch.init();
  carousel.init();
  helpers.init();
  polyfills.init();
  siteNavigation.init();
  siteSearch.init();
  scrollTop.init();
  activeState.init();
  card.init();
  socialSharing.init();
  formSubmission.init();
  imageGalleryBlock.init();
  stickySocialSharing.init();
  priceBar.init();
  featuredProducts.init();
  generalImageListPromoBlock.init();
  checkoutFormSubmission.init();
  forms.init();
  analytics.init();
  currencySwitcher.init();
});
