<template>
  <div class="range-slider" v-show="priceRangeMaxPrice != null">
    <p class="range-slider__title">{{ label }}</p>
    <div class="range-slider__body">
      <ul class="range-slider__graph">
        <li
          class="range-slider__graph-item"
          v-for="(bar, key) in priceRangeBarHeights"
          :style="{ height: bar + '%', width: barWidth + '%' }"
          :key="key"
        ></li>
      </ul>
      <div class="slider-range js-slider-range"></div>
    </div>
    <p class="slider-amount" v-if="currentCurrency">
      {{ currentCurrency(sliderMin).format() }} -
      {{ currentCurrency(sliderMax).format() }}
    </p>
  </div>
</template>

<script>
import { ref, computed, watch, onMounted } from 'vue';
import { useStore } from 'vuex';

export default {
  name: 'RangeSlider',
  props: {
    store: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: 'Price Range',
    },
  },

  setup(props) {
    const sliderMin = ref(null);
    const sliderMax = ref(null);
    const slider = ref(null);
    const currentCurrency = ref(null);
    const store = useStore();
    const priceRangeBarHeights = computed(
      () => store.state[props.store].PriceRangeBarHeights,
    );
    const priceRangeMaxPrice = computed(
      () => store.state[props.store].PriceRangeMaxPrice,
    );
    const priceRangeMinPrice = computed(
      () => store.state[props.store].PriceRangeMinPrice,
    );
    const maxPrice = computed(() => store.state[props.store].MaxPrice);
    const minPrice = computed(() => store.state[props.store].MinPrice);
    const currencyFormatInfo = computed(
      () => store.state[props.store].currencyFormatInfo,
    );
    const barWidth = computed(() => 100 / priceRangeBarHeights.value.length);

    const setPrice = () => {
      store.dispatch(`${props.store}/setMinPrice`, sliderMin.value);
      store.dispatch(`${props.store}/setMaxPrice`, sliderMax.value);
      store.dispatch(`${props.store}/fetchItems`);
    };

    const createSlider = () => {
      slider.value = $('.js-slider-range').slider({
        range: true,
        slide: function (event, ui) {
          sliderMin.value = ui.values[0];
          sliderMax.value = ui.values[1];
        },
        change: function (event, ui) {
          console.log('slider change');
          sliderMin.value = ui.values[0];
          sliderMax.value = ui.values[1];
        },
        stop: function (event, ui) {
          if (
            sliderMin.value !== minPrice.value ||
            sliderMax.value !== maxPrice.value
          ) {
            setPrice();
          }
        },
      });

      if (priceRangeMinPrice.value) {
        slider.value.slider('option', 'min', priceRangeMinPrice.value);
      }
      if (priceRangeMaxPrice.value) {
        slider.value.slider('option', 'max', priceRangeMaxPrice.value);
        if (maxPrice.value == null || maxPrice.value === '') {
          slider.value.slider('values', 1, priceRangeMaxPrice.value);
        }
      }
      if (minPrice.value) {
        slider.value.slider('values', 0, minPrice.value);
      }
      if (maxPrice.value) {
        if (priceRangeMaxPrice.value == null) {
          slider.value.slider('option', 'max', maxPrice.value);
        }
        slider.value.slider('values', 1, maxPrice.value);
      }
      // }
    };

    watch(priceRangeMinPrice, val => {
      slider.value.slider('option', 'min', val);
    });

    watch(priceRangeMaxPrice, val => {
      slider.value.slider('option', 'max', val);
      if (maxPrice.value == null || maxPrice.value === '') {
        slider.value.slider('values', 1, val);
      }
    });

    watch(minPrice, val => {
      slider.value.slider('values', 0, val);
    });

    watch(maxPrice, val => {
      if (priceRangeMaxPrice.value == null) {
        slider.value.slider('option', 'max', val);
      }
      slider.value.slider('values', 1, val);
    });

    watch(currencyFormatInfo, () => {
      currentCurrency.value = value =>
        currency(value, {
          symbol: currencyFormatInfo.value.CurrencySymbol,
          separator: currencyFormatInfo.value.CurrencyGroupSeparator,
          decimal: currencyFormatInfo.value.CurrencyDecimalSeparator,
          precision: 0,
          pattern: currencyFormatInfo.value.CurrencyPositivePattern,
          negativePattern: currencyFormatInfo.value.CurrencyNegativePattern,
          formatWithSymbol: true,
        });
    });

    onMounted(() => {
      createSlider();
    });

    return {
      sliderMin,
      sliderMax,
      slider,
      currentCurrency,
      priceRangeBarHeights,
      priceRangeMaxPrice,
      priceRangeMinPrice,
      maxPrice,
      minPrice,
      barWidth,
      setPrice,
    };
  },
};
</script>
