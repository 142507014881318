
const options = {
  searchModal: '#search-modal',
  searchBtn: '.js-search-toggle-btn'
};

const init = () => {

  $(options.searchModal).attr('aria-hidden', 'true');

  $(options.searchBtn).each((index, el) => {

    let $this = $(el);

    // add ARIA attributes
    $this.attr('aria-controls', $(options.searchModal).attr('ID'));
    $this.attr('aria-expanded', 'false');

    $this.on('click', () => {

      let state = $this.attr('aria-expanded') === 'false' ? true : false;
      $(options.searchBtn).attr('aria-expanded', state);
      $(options.searchModal).attr('aria-hidden', !state);

      // $('html').toggleClass('search-active');

      // set focus on the coresponding other open/close button
      if ( state ) {

        // var scrollTop = ($('html').scrollTop()) ? $('html').scrollTop() : $('body').scrollTop(); // Works for Chrome, Firefox, IE...
        var scrollTop = $(window).scrollTop();
        if (scrollTop < 0) { scrollTop = 0; }
        window.scrollTop = scrollTop;

        /*
        var menuHeight = $('.header-main').outerHeight()
        if(scrollTop < $('.secondary-nav').outerHeight()) {
          menuHeight += $('.secondary-nav').outerHeight() - scrollTop;
        }
        $(options.searchModal).css('top', menuHeight);


        if($('.header').hasClass('stuck')) {
          scrollTop = $('.secondary-nav').outerHeight();
        }

        $('html').addClass('search-active').css('top', -scrollTop);
        */
        $('html').addClass('search-active');

        // hide elements behind the search overlay from focus
        $('a').not('#site-search a').attr('tabindex','-1');
        $('a').not('#site-search a').attr('style','cursor:default;');
        $('button').not('#site-search button').not(options.searchBtn).attr('disabled','true');
        $('input').not('#site-search input').attr('disabled','true');

        $('.js-search-modal input').focus();

      } else {

        $('html').removeClass('search-active');

        // console.log('window.scrollTop  ' + window.scrollTop);
        // $('html,body').scrollTop(window.scrollTop);

        // restore focus to elements behind the search overlay
        $('a').not('#site-search a').attr('tabindex','0');
        $('a').not('#site-search a').attr('style', 'cursor:pointer;');
        $('button').not('#site-search button').removeAttr('disabled');
        $('input').not('#site-search input').removeAttr('disabled');
        $('#search-btn').focus();

        $(window).scrollTop(window.scrollTop);
      }

    });

  });

  // close mobile menu by pressing escape
  // may need a more centralised approach for handling key codes
  $(window).keyup( (e) => {
    if (e.keyCode === 27) {
      e.preventDefault();
      if ( $('html').hasClass('search-active') ) {
        $('html').removeClass('search-active');
        // set focus back on the element that opened the modal
        $('#search-btn').focus();
      }
    }
  });


};

export { init }