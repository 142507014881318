const init = () => {
  $('.js-general-image-list-promo').slick({
    dots: true,
    arrows: false,
    adaptiveHeight: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    mobileFirst: true,
    infinite: false,
    responsive: [
      {
        breakpoint: 768,
        settings: 'unslick',
      },
    ],
  });
};

export { init };
