export default {
  fetchItems({ commit, getters, dispatch }, replaceUrl) {
    let postData = {
      ...getters.urlData,
      ...getters.serverData,
    };

    var apiUrl;
    if (postData.ListingPageId) {
      apiUrl = getters.apiUrl('urlContentPage');
    } else {
      apiUrl = getters.apiUrl('urlContentSearch');
    }

    fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(postData),
    })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error('Network response was not ok');
        }
      })
      .then(data => {
        dispatch('setStateData', data);
        commit('setCurrentSearch', postData.SearchTerm);

        const query = new URLSearchParams(getters.urlData);
        const oldUrl = `${location.origin}${location.pathname}${location.search}`;
        const newUrl = `${location.origin}${
          location.pathname
        }?${query.toString()}`;
        if (oldUrl !== newUrl) {
          if (replaceUrl) {
            history.replaceState(null, null, newUrl);
          } else {
            history.pushState(null, null, newUrl);
          }
        }
      })
      .catch(error => {
        console.log('e', error);
      });
  },

  setStateData({ commit }, data) {
    commit('setItems', data.Result.Items);
    commit('setItemsRemaining', data.Result.Remaining);
    commit('setItemsCount', data.Result.Total);
    commit('setDisplayText', data.DisplayText);
  },

  fetchSortOrderOptions({ getters, state, commit }) {
    fetch(getters.apiUrl('urlSortOrder'))
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error('Network response was not ok');
        }
      })
      .then(data => {
        if (!state.SortOrder) {
          commit('setSortOrder', data[0].Key);
        }
        commit('setSortOrderOptions', data);
      })
      .catch(error => {
        console.log('Error:', error);
      });
  },
};
