export default {
  clearError({ commit }) {
    commit('setErrorStatus', '');
  },

  loadCount({ commit }) {
    fetch('/umbraco/Api/reservationsapi/getbasketcount')
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error('Network response was not ok');
        }
      })
      .then(data => {
        console.log('success: ' + JSON.stringify(data, null, 2));
        const basketCount = data;
        commit('setCount', basketCount);
      })
      .catch(error => {
        console.log('fail: ' + JSON.stringify(error, null, 2));
        commit('setErrorStatus', true);
      });
  },

  loadBasket({ commit }) {
    fetch('/umbraco/Api/reservationsapi/getreservations')
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error('Network response was not ok');
        }
      })
      .then(data => {
        if (data.Reservations) {
          commit('setBasket', data.Reservations);
          commit('setCount', data.Reservations.length);
          commit('setTotal', data.Total);
          commit('setSubTotal', data.SubTotal);
          commit('setPromoCodes', data.PromodCodes);

          if (data.PromodCodes != null && data.PromodCodes.length > 0) {
            commit('setPromoApplied', true);
          }
        } else {
          commit('setBasket', []);
          commit('setCount', 0);
        }
        commit('setBasketReservationId', data.BasketReservationId);
      })
      .catch(error => {
        console.log('fail: ' + JSON.stringify(error, null, 2));
        commit('setErrorStatus', true);
      });
  },

  addToBasket({ commit, dispatch }, data) {
    fetch('/umbraco/Api/ReservationsApi/CreateReservation', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error('Network response was not ok');
        }
      })
      .then(data => {
        commit('setProductAdded', data.DisplayProduct);
        commit('setHoldItemText', data.HoldItemText);
        commit('setRelatedProducts', data.RelatedProducts);
        dispatch('loadCount');
      })
      .catch(error => {
        commit('setErrorStatus', true);
      });
  },

  clearProductAdded({ commit }) {
    commit('setProductAdded', null);
  },

  setHoldItemText({ commit }, data) {
    commit('setHoldItemText', data);
  },

  setProductToRemove({ commit }, data) {
    commit('setProductToRemove', data);
  },

  removeProduct({ commit, dispatch }, data) {
    commit('setIsBusy', true);

    fetch('/umbraco/Api/reservationsapi/cancelreservation', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then(() => {
        commit('setIsBusy', false);
        commit('setProductToRemove', null);
        commit('setProductRemoved', true);
        dispatch('loadBasket');
      })
      .catch(() => {
        commit('setIsBusy', false);
        commit('setErrorStatus', true);
      });
  },

  setProductRemoved({ commit }, data) {
    commit('setProductRemoved', data);
  },

  setProductToEdit({ commit }, data) {
    commit('setProductToEdit', data);
  },

  editProduct({ commit, dispatch }, data) {
    commit('setIsBusy', true);
    fetch('/umbraco/Api/ReservationsApi/CreateReservation', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then(() => {
        commit('setIsBusy', false);
        commit('setProductToEdit', null);
        commit('setProductEdited', true);
        dispatch('loadBasket');
      })
      .catch(() => {
        commit('setIsBusy', false);
        commit('setErrorStatus', true);
      });
  },
};
